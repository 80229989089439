import React, {Component} from 'react';
import Button from '../Button';


class ErreurVote extends Component{
  constructor(props){
    super(props);
    this.state = {
    	
    }
    this.myRef = React.createRef();
  }

  componentDidMount(){
     if (this.myRef.current != null){
        this.myRef.current.focus();
      }
  }

  render(){
      return(
        <div className="evaluation-fin">
            <div>
     
                <h1 tabIndex="-1" ref={this.myRef}>Erreur</h1>
                <p>Une erreur est survenue lors de l'envoi du vote, veuillez reessayer.</p>

                <button onClick={this.props.onClick} className="rbtn"><div>Reessayer <span aria-hidden="true" data-icon=">"></span></div></button>

                <Button path="/" text="Évaluer un autre programme"/>
             
            </div>
           
        </div>
      )
  }
  
}

export default ErreurVote;