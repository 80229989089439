import React, { Component } from 'react';
import Button from '../Button';
import { Tooltip, Slider } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import $ from 'jquery';


class EvaluationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessibilite: props.match.params.accessibilite,
      id: props.match.params.programmeID
    }
    this.myRef = React.createRef();
    this.rating_qual_lsf = this.props.rating_qual_lsf
    this.rating_qual_lsf_changed = false
    this.qual_lsf_text = isNaN(this.props.rating_qual_lsf) || this.props.rating_qual_lsf < 5 ? "À améliorer" : (this.props.rating_qual_lsf < 8 ? "Moyen" : "Bon")
    this.qual_lsf_class = "moyen"
  }

  componentDidMount() {
    if (this.myRef.current != null) {
      this.myRef.current.focus();
    }

    $('.infobulle').on("click", function (e) {
      e.preventDefault();
      console.log($(this));
      alert($(this).text());

    })

    $("[role=slider]").attr("aria-required","true")

    $.fn.inputFilter = function(inputFilter) {
      console.log("filter", inputFilter(this.value))
      return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
        $(this).attr('aria-invalid',this.value=="")
      });
    };

    $("#rating_qual_lsf_input").inputFilter(function(value) {
      return /^\d*$/.test(value) && (value === "" || parseInt(value) <= 10); });
  }


  handleLSFRatingChange(value) {
    this.rating_qual_lsf = value
    $("#rating_qual_lsf, #qual_lsf_text").removeClass("mauvais moyen bon")
    if (value < 5) {
      this.qual_lsf_class = "mauvais"
      this.qual_lsf_text = "À améliorer"
    }
    else if (value < 8) {
      this.qual_lsf_class = "moyen"
      this.qual_lsf_text = "Moyen"
    } else {
      this.qual_lsf_class = "bon"
      this.qual_lsf_text = "Bon"
    }
    $("#rating_qual_lsf, #qual_lsf_text").addClass(this.qual_lsf_class)
    $("#qual_lsf_text").text(this.qual_lsf_text)
    $("#rating_qual_lsf_input").val(this.rating_qual_lsf)
    this.rating_qual_lsf_changed = true
  }

  validateForm(){
    if( isNaN(this.rating_qual_lsf)){
      $("#rating_qual_lsf_input").attr('aria-invalid',true)
      alert("Le champ note ne doit pas être vide");
      return false;
    }
      else if( this.state.accessibilite==="lsf" && this.props.precision.length == 0 && !this.rating_qual_lsf_changed){
        return window.confirm("Vous n'avez choisi aucun critère ni changé la note de qualité de l'interprétation. Êtes-vous sûr de vouloir valider le formulaire ?")
      } /*else if( this.state.accessibilite==="lsf"  && this.rating_qual_lsf_changed == false){
        return window.confirm("Vous n'avez pas changé la note de qualité de l'interprétation. Êtes vous sûr de vouloir la laisser à 5 ?")
      }*/

      return true
    
  }

  onSubmit(){
    if(!this.validateForm()){
      return false
    }
    this.props.onLSFRatingChange(this.rating_qual_lsf)
    return true
  }

  render() {
    console.log("rating_qual_lsf", this.rating_qual_lsf)

    const color = isNaN(this.props.rating_qual_lsf) || this.props.rating_qual_lsf < 5 ? "#AD0000" : (this.props.rating_qual_lsf < 8 ? "#D14600" : "#006100")

    this.qual_lsf_text = isNaN(this.props.rating_qual_lsf) || this.props.rating_qual_lsf < 5 ? "À améliorer" : (this.props.rating_qual_lsf < 8 ? "Moyen" : "Bon")

    this.qual_lsf_class = isNaN(this.props.rating_qual_lsf) || this.props.rating_qual_lsf < 5 ? "mauvais" : (this.props.rating_qual_lsf < 8 ? "moyen" : "bon")

    this.rating_qual_lsf = this.props.rating_qual_lsf

    this.rating_qual_lsf_changed = this.rating_qual_lsf != 5

    const PrettoSlider = withStyles({
      root: {
        color: color,
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
          boxShadow: 'inherit',
        },
      },
      active: {},
      valueLabel: {
        left: 'calc(-50% + 4px)',
      },
      track: {
        height: 8,
        borderRadius: 4,
      },
      rail: {
        height: 8,
        borderRadius: 4,
      },
    })(Slider);
    return (
      <div className={"evaluation evaluation-details " + this.state.accessibilite}>
        <div className="criterias">
          <h3 tabIndex="-1" ref={this.myRef}>Pourquoi ce n'est pas satisfaisant ?</h3>
          <div className="content">
            <Route path="/programme/:programmeID/evaluation/sous_titrage">
              <div className="row">
                <div className="col">
                  <div className="custom-control custom-checkbox checkbox-xl my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="partiel"
                      checked={this.props.precision.includes("partiel")}
                      onChange={() => this.props.changeCheckBox("partiel")} />
                    <label className="custom-control-label" htmlFor="partiel">Sous-titrage partiel
                        <Tooltip tabIndex="0" title="Apparition partielle du sous-titrage">
                        <div className="infobulle">
                          <p className="screen-reader-text">Apparition partielle du sous-titrage</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>

                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="erreur_retranscription"
                      checked={this.props.precision.includes("erreur_retranscription")}
                      onChange={() => this.props.changeCheckBox("erreur_retranscription")} />
                    <label className="custom-control-label" htmlFor="erreur_retranscription">Erreur de retranscription
                        <Tooltip tabIndex="0" title="Différence entre ce qui est dit et ce qui est sous-titré">
                        <div className="infobulle">
                          <p className="screen-reader-text">Différence entre ce qui est dit et ce qui est sous-titré</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="pas_tps_lire"
                      checked={this.props.precision.includes("pas_tps_lire")}
                      onChange={() => this.props.changeCheckBox("pas_tps_lire")} />
                    <label className="custom-control-label" htmlFor="pas_tps_lire">Pas le temps de lire</label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="pas_bandeau"
                      checked={this.props.precision.includes("pas_bandeau")}
                      onChange={() => this.props.changeCheckBox("pas_bandeau")} />
                    <label className="custom-control-label" htmlFor="pas_bandeau"> Absence de bandeau noir</label>
                  </div>


                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="non_respect_deux_ligne_sttr"
                      checked={this.props.precision.includes("non_respect_deux_ligne_sttr")}
                      onChange={() => this.props.changeCheckBox("non_respect_deux_ligne_sttr")} />
                    <label className="custom-control-label" htmlFor="non_respect_deux_ligne_sttr">Non-respect des 2 lignes de sous-titrage en différé et des 3 lignes en direct</label>
                  </div>

                </div>
                <div className="col">


                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="non_sens_partiel"
                      checked={this.props.precision.includes("non_sens_partiel")}
                      onChange={() => this.props.changeCheckBox("non_sens_partiel")} />
                    <label className="custom-control-label" htmlFor="non_sens_partiel">Non-sens partiel
                        <Tooltip tabIndex="0" title="On ne comprend pas ce qui est écrit">
                        <div className="infobulle">
                          <p className="screen-reader-text">On ne comprend pas ce qui est écrit</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="non_sens"
                      checked={this.props.precision.includes("non_sens")}
                      onChange={() => this.props.changeCheckBox("non_sens")} />
                    <label className="custom-control-label" htmlFor="non_sens">Non-sens global
                        <Tooltip tabIndex="0" title="On ne comprend pas ce qui est écrit">
                        <div className="infobulle">
                          <p className="screen-reader-text">On ne comprend pas ce qui est écrit</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="fautes_francais"
                      checked={this.props.precision.includes("fautes_francais")}
                      onChange={() => this.props.changeCheckBox("fautes_francais")} />
                    <label className="custom-control-label" htmlFor="fautes_francais">Fautes de Français
                        <Tooltip tabIndex="0" title="Fautes d’orthographe, de grammaire ou de conjugaison">
                        <div className="infobulle">
                          <p className="screen-reader-text">Fautes d’orthographe, de grammaire ou de conjugaison</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="retard"
                      checked={this.props.precision.includes("retard")}
                      onChange={() => this.props.changeCheckBox("retard")} />
                    <label className="custom-control-label" htmlFor="retard">Sous-titrage décalé
                        <Tooltip tabIndex="0" title="Décalage entre l’image à l’écran ou ce qui est dit et le sous-titrage">
                        <div className="infobulle">
                          <p className="screen-reader-text">Décalage entre l’image à l’écran ou ce qui est dit et le sous-titrage</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>

                    </label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="qui_parle"
                      checked={this.props.precision.includes("qui_parle")}
                      onChange={() => this.props.changeCheckBox("qui_parle")} />
                    <label className="custom-control-label" htmlFor="qui_parle">On ne sait pas qui parle</label>
                  </div>



                </div>

                <div className="col">

                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="manque_contraste"
                      checked={this.props.precision.includes("manque_contraste")}
                      onChange={() => this.props.changeCheckBox("manque_contraste")} />
                    <label className="custom-control-label" htmlFor="manque_contraste">Manque de contraste entre le texte et l'arrière plan</label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="superposition"
                      checked={this.props.precision.includes("superposition")}
                      onChange={() => this.props.changeCheckBox("superposition")} />
                    <label className="custom-control-label" htmlFor="superposition">Superposition avec d'autres écrits</label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="pas_code_couleur"
                      checked={this.props.precision.includes("pas_code_couleur")}
                      onChange={() => this.props.changeCheckBox("pas_code_couleur")} />
                    <label className="custom-control-label" htmlFor="pas_code_couleur">Pas de code couleurs
                          <Tooltip tabIndex="0" title="Si sous-titrage sourds et malentendants : Absence d’informations sonores/ Pas d’information sur les bruits ambiants (musique, bruit de pas, etc.) ">
                        <div className="infobulle">
                          <p className="screen-reader-text">Si sous-titrage sourds et malentendants : Absence d’informations sonores/ Pas d’information sur les bruits ambiants (musique, bruit de pas, etc.)</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="respect_code_couleur"
                      checked={this.props.precision.includes("respect_code_couleur")}
                      onChange={() => this.props.changeCheckBox("respect_code_couleur")} />
                    <label className="custom-control-label" htmlFor="respect_code_couleur">Non-respect des couleurs
                        <Tooltip tabIndex="0" title="Dans le cas d'un sous-titrage sourds et malentendants">
                        <div className="infobulle">
                          <p className="screen-reader-text">Dans le cas d'un sous-titrage sourds et malentendants</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                </div>
              </div>
            </Route>
            <Route path="/programme/:programmeID/evaluation/lsf">
              <div className="row">
                <div className="col">
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="taille_medaillon"
                      checked={this.props.precision.includes("taille_medaillon")}
                      onChange={() => {this.props.changeCheckBox("taille_medaillon"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="taille_medaillon">Taille du médaillon de l'interprète trop petite</label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="mauvais_cadrage"
                      checked={this.props.precision.includes("mauvais_cadrage")}
                      onChange={() => {this.props.changeCheckBox("mauvais_cadrage"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="mauvais_cadrage">Mauvais cadrage de l'interprète</label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="mauvaises_lumieres"
                      checked={this.props.precision.includes("mauvaises_lumieres")}
                      onChange={() => {this.props.changeCheckBox("mauvaises_lumieres"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="mauvaises_lumieres">Mauvaises lumières sur l'interprète</label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="mauvais_contraste"
                      checked={this.props.precision.includes("mauvais_contraste")}
                      onChange={() => {this.props.changeCheckBox("mauvais_contraste"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="mauvais_contraste">Mauvais contraste entre l’arrière-plan et l’interprète</label>
                  </div>

                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="mauvais_distinction"
                      checked={this.props.precision.includes("mauvais_distinction")}
                      onChange={() => {this.props.changeCheckBox("mauvais_distinction"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="mauvais_distinction">Mauvaise distinction des interlocuteurs en cas d’échanges complexes </label>
                  </div>

                  



                </div>
                <div className="col">

                <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="coupure_interpretation"
                      checked={this.props.precision.includes("coupure_interpretation")}
                      onChange={() => {this.props.changeCheckBox("coupure_interpretation"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="coupure_interpretation">Coupure de l’interprétation avant la fin de l’émission</label>
                  </div>

                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="mauvais_placement"
                      checked={this.props.precision.includes("mauvais_placement")}
                      onChange={() => {this.props.changeCheckBox("mauvais_placement"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="mauvais_placement">Mauvais placement de l'interprète
                        <Tooltip tabIndex="0" title="Informations textuelles ou graphiques gênant la visibilité de l’interprète">
                        <div className="infobulle">
                          <p className="screen-reader-text">Informations textuelles ou graphiques gênant la visibilité de l’interprète</p>
                          <HelpIcon />
                        </div>
                      </Tooltip></label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="message_incomprehensible"
                      checked={this.props.precision.includes("message_incomprehensible")}
                      onChange={() => {this.props.changeCheckBox("message_incomprehensible"); ; this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="message_incomprehensible">Message incompréhensible
                        <Tooltip tabIndex="0" title="Le niveau de LSF de l’interprète ne permet pas de comprendre">
                        <div className="infobulle">
                          <p className="screen-reader-text">Le niveau de LSF de l’interprète ne permet pas de comprendre</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>

                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="debit_non_adapte"
                      checked={this.props.precision.includes("debit_non_adapte")}
                      onChange={() =>{this.props.changeCheckBox("debit_non_adapte"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="debit_non_adapte">Débit non adapté
                        <Tooltip tabIndex="0" title="La vitesse de traduction est trop lente ou trop rapide">
                        <div className="infobulle">
                          <p className="screen-reader-text">La vitesse de traduction est trop lente ou trop rapide</p>
                          <HelpIcon />
                        </div>
                      </Tooltip>
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox my-1 mr-sm-2">
                    <input type="checkbox" className="custom-control-input" id="mauvaise_tenue"
                      checked={this.props.precision.includes("mauvaise_tenue")}
                      onChange={() =>{this.props.changeCheckBox("mauvaise_tenue"); this.props.onLSFRatingChange(this.rating_qual_lsf)}} />
                    <label className="custom-control-label" htmlFor="mauvaise_tenue">Mauvaise tenue vestimentaire de l’interprète</label>
                  </div>

                </div>
              </div>
              <h3>Veuillez noter le niveau de qualité de l'interprétation en LSF</h3>


              <div id="slider-block">

                <PrettoSlider id="rating_qual_lsf" valueLabelDisplay="on" aria-label="Qualité de l'interprétation LSF (note entre 0 et 10). Utilisez les flèches gauche et droite pour modifier la valeur. Si vous êtes sur mobile ou que vous ne parveznez pas à modifier la note, vous pouvez utilisez le champ de texte si dessous pour rentrer directement une note" defaultValue={isNaN(this.props.rating_qual_lsf)? 0 : this.props.rating_qual_lsf} min={0} max={10} step={1} onChange={(e, value) => this.handleLSFRatingChange(value)}/>

                <p id="qual_lsf_text" aria-live="polite" className={this.qual_lsf_class}>{this.qual_lsf_text}</p>


                <label className="screen-reader-text" tabIndex="-1">Qualité de l'interprétation LSF (note entre 0 et 10) <input aria-required="true" type="text" id="rating_qual_lsf_input" defaultValue={isNaN(this.props.rating_qual_lsf)? "" : this.props.rating_qual_lsf} onChange={e => this.props.onLSFRatingChange(parseInt(e.target.value))}/></label>
              </div>
              {/*  <Tooltip tabIndex="0" title={<ul id="tooltip-qual-lsf"><li>Le niveau LSF de l'interprète est suffisant pour comprendre le message</li><li>Le débit est adapté (la vitesse de traduction n'est pas trop lente ou trop rapide)</li></ul>} >
                <div className="infobulle">
                  <p className="screen-reader-text">Uniquement sur les critères suivants :</p>


                  <HelpIcon />
                </div>
            </Tooltip>-->*/}

            </Route>
            <Route path="/programme/:programmeID/evaluation/:accessibilite/audio_description">

            </Route>
          </div>
        </div>

        <div className="bottom-bar">
          {this.props.precision.length == 0  && this.state.accessibilite==="sous_titrage" &&
            <p className="screen-reader-text">Vous devez selectionner au moins un critère pour pouvoir continuer</p>
          }
          {(this.props.precision.length > 0 || this.state.accessibilite==="lsf") && <Button path={"/programme/" + this.state.id + "/evaluation/" + this.state.accessibilite + "/profil"} onClickCondition={() => this.onSubmit() } className="rbtn send_vote_btn" text="Valider" />}
        </div>


      </div>
    )
  }

}

export default EvaluationDetails;