import React, { useEffect, useState } from 'react';
import imgLive from '../img/menu/img-cat-live.png';
import imgFilms from '../img/menu/img-cat-films.png';
import imgTv from '../img/menu/img-cat-tv.png';
import imgCalendrier from '../img/menu/img-calendrier-pick.png';
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
import 'react-dates/initialize';
import 'moment/locale/fr';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import $ from 'jquery';
registerLocale("fr", fr); // register it with the name you want

function setVoiceOverFocus(element) {
    var focusInterval = 10; // ms, time between function calls
    var focusTotalRepetitions = 10; // number of repetitions

    element.setAttribute('tabindex', '0');
    element.blur();

    var focusRepetitions = 0;
    var interval = window.setInterval(function () {
        element.focus();
        focusRepetitions++;
        if (focusRepetitions >= focusTotalRepetitions) {
            window.clearInterval(interval);
        }
    }, focusInterval);
}

export default (props) => {

    moment.locale('fr');


    const [selectedCats, setSelectedCats] = useState(props.cats || []);

    const toggleCats = (cat) => {
        console.log(selectedCats, cat);
        if (selectedCats.indexOf(cat) === -1) setSelectedCats([cat, ...selectedCats]);
        else {
            selectedCats.splice(selectedCats.indexOf(cat), 1)
            setSelectedCats([...selectedCats]);
        }
    }

    const reset = () => {
        setSelectedCats([]);
        setStartDate(moment().subtract(5, 'days').startOf('day'))
        setEndDate(moment())
        setChaine("all");
    }

    const [startDate, setStartDate] = useState(props.startDate || moment());
    const [endDate, setEndDate] = useState(props.endDate || new moment());
    const [focusedInput, setFocusedInput] = useState(null);
    const [focused, setFocused] = useState(false);
    const [historiqueByDay, setHistoriqueByDay] = useState([])
    const [chaine, setChaine] = useState(props.chaine);
    const falseFunc = () => false;

    useEffect(() => {
       

        $('.DateInput').append("<img src='/img/img-calendrier-pick.png' alt=''>")
        $('.DateInput').on('click',function(){
            $(this).find("input").trigger('focus');
        });

    }, []);

    const rangefunc = date => /*date.isBefore(minDate, 'day') || */date.isAfter(moment(), 'day')
    const replayrangefunc = date => /*date.isBefore(minDate, 'day') || */date.isAfter(moment().add('days', 10), 'day')
    return <div id="filtres-programmes" className="mx-3 text-left mb-2 filters">
        <div className="row no-gutters">

            {props.from === "accueil" && <div className="col">
                <p>Filtrer par <b>Chaine</b> :</p>
                <FormControl variant="outlined" >
                    <InputLabel id="chaine-label">Chaine</InputLabel>
                    <Select
                        labelId="chaine-label"
                        id="chaine-select-outlined"
                        value={chaine}
                        onChange={(event) => setChaine(event.target.value)}
                        label="Chaine"
                    >
                        <MenuItem value={"all"}>Toutes</MenuItem>
                        {props.chaines.map(chaine =>
                            <MenuItem value={chaine.id}>{chaine.titre}</MenuItem>
                        )}
                    </Select>

                </FormControl>
            </div>
            }
            {/*<div className="col-md-6 mt-3 pr-md-3">
                <p>Filtrer par <b>Type</b> :</p>
                <div className="filters-cat d-flex justify-content-between">
                    <a href="#" role="checkbox" aria-checked={selectedCats.indexOf('live') > -1} className={`filter ${selectedCats.indexOf('live') > -1 ? 'selected' : 'live'}`} onClick={(e) => toggleCats('live')}>
                        <img src={imgLive} alt="Live"/>
                    </a>
                    <a href="#" role="checkbox" aria-checked={selectedCats.indexOf('films') > -1} className={`filter ${selectedCats.indexOf('films') > -1 ? 'selected' : 'films'}`} onClick={(e) => toggleCats('films')}>
                        <img src={imgFilms} alt="Films" />
                    </a>
                    <a href="#" role="checkbox" aria-checked={selectedCats.indexOf('tv') > -1} className={`filter ${selectedCats.indexOf('tv') > -1 ? 'selected' : 'tv'}`} onClick={(e) => toggleCats('tv')}>
                        <img src={imgTv} alt="TV"/>
                    </a>
                </div>
*/}
            <div className="col">
                <p>Filtrer par <b>Date</b> :</p>
                {props.from === "classement" && <DateRangePicker
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId="start_date_id" // PropTypes.string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId="end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => {
                        
                        setStartDate(startDate); setEndDate(endDate)
                    }
                    } // PropTypes.func.isRequired,
                    focusedInput={focusedInput}
                    onFocusChange={newfocusedInput => setFocusedInput(newfocusedInput)}
                    displayFormat={() => moment.localeData('fr').longDateFormat('L')}
                    startDatePlaceholderText="Date de début"
                    startDateAriaLabel="Date de début (Format JJ barre oblique MM barre oblique A A A A)"
                    endDateAriaLabel="Date de fin (Format JJ barre oblique MM barre oblique A A A A)"
                    endDatePlaceholderText="Date de fin"
                    isOutsideRange={rangefunc}
                    phrases={{
                        calendarLabel: 'Calendrier',
                        roleDescription: 'Utiliser le calendrier pour choisir la date de début et la date de fin. Vous pouvez aussi rentrer directement les dates dans les champs de textes associés au format JJ barre oblique MM barre oblique AAAA',
                        closeDatePicker: 'Fermer',
                        clearDates: 'Effacer les dates',
                        focusStartDate: 'Interagissez avec le calendrier pour ajouter les dates de début et de fin',
                        jumpToPrevMonth: 'Aller au mois précédent',
                        jumpToNextMonth: 'Aller au mois suvant.',
                        keyboardShortcuts: 'Raccourcis clavier',
                        showKeyboardShortcutsPanel: 'Ouvrir le panneau Raccourcis clavier.',
                        hideKeyboardShortcutsPanel: 'Fermer le panneau Raccourcis clavier.',
                        openThisPanel: 'Ouvrir ce panneau.',
                        enterKey: 'Touche Entrée',
                        leftArrowRightArrow: 'Touches Gauche et Droite',
                        upArrowDownArrow: 'Touches Haut et Bas',
                        pageUpPageDown: 'Touches page précédente et page suivante',
                        homeEnd: 'Touches de début et de fin',
                        escape: 'Touche Echap',
                        questionMark: "Point d'interrogation",
                        selectFocusedDate: "Selectionne la date en focus",
                        moveFocusByOneDay: "Va au jour précédent (Gauche) ou suivant (Droite)",
                        moveFocusByOneWeek: 'Va à la semaine précédente (Haut) ou suivante (Bas)',
                        moveFocusByOneMonth: 'Passe au mois suivant',
                        moveFocustoStartAndEndOfWeek: 'Va au premier ou au dernier jour de la semaine',
                        returnFocusToInput: "Retourne au champ de texte d'entrée de date",
                        keyboardNavigationInstructions: "Appuyez sur la touche du bas pour intéragir avec le calendrier et choisir une date. Vous pouvez aussi rentrer directement la date dans le champ de texte au format JJ barre oblique MM barre oblique AAAA",

                        chooseAvailableStartDate: ({ date }) => `Choisir ${date} comme date de début.`,
                        chooseAvailableEndDate: ({ date }) => `Choisir ${date} comme date de fin.`,
                        dateIsUnavailable: ({ date }) => `Non disponible ${date}`,
                        dateIsSelected: ({ date }) => `Sélectionné. ${date}`,
                        dateIsSelectedAsStartDate: ({ date }) => `Selectionné comme date de départ. ${date}`,
                        dateIsSelectedAsEndDate: ({ date }) => `Selected comme date de fin. ${date}`,
                    }}
                    numberOfMonths={1}
                />
                }
                {props.from === "accueil" &&
                    <SingleDatePicker
                        numberOfMonths={1}
                        date={endDate} // momentPropTypes.momentObj or null
                        onDateChange={date => {setEndDate(date); setVoiceOverFocus($("#apply")[0])}} // PropTypes.func.isRequired
                        focused={focused} // PropTypes.bool
                        onFocusChange={(newfocused) => { setFocused(newfocused.focused) }} // PropTypes.func.isRequired
                        id="datePicker" // PropTypes.string.isRequired,
                        isOutsideRange={replayrangefunc}
                        ariaLabel="Date (Format JJ barre oblique MM barre oblique A A A A)"
                        phrases={{
                            calendarLabel: 'Calendrier',
                            roleDescription: 'Utiliser le calendrier pour choisir une date. Vous pouvez aussi rentrer directement la date dans le champ de texte au format JJ barre oblique MM barre oblique AAAA',
                            closeDatePicker: 'Fermer',
                            clearDates: 'Effacer les dates',
                            focusStartDate: 'Interagissez avec le calendrier pour ajouter une date.',
                            jumpToPrevMonth: 'Aller au mois précédent',
                            jumpToNextMonth: 'Aller au mois suvant.',
                            keyboardShortcuts: 'Raccourcis clavier',
                            showKeyboardShortcutsPanel: 'Ouvrir le panneau Raccourcis clavier.',
                            hideKeyboardShortcutsPanel: 'Fermer le panneau Raccourcis clavier.',
                            openThisPanel: 'Ouvrir ce panneau.',
                            enterKey: 'Touche Entrée',
                            leftArrowRightArrow: 'Touches Gauche et Droite',
                            upArrowDownArrow: 'Touches Haut et Bas',
                            pageUpPageDown: 'Touches page précédente et page suivante',
                            homeEnd: 'Touches de début et de fin',
                            escape: 'Touche Echap',
                            questionMark: "Point d'interrogation",
                            selectFocusedDate: "Selectionne la date en focus",
                            moveFocusByOneDay: "Va au jour précédent (Gauche) ou suivant (Droite)",
                            moveFocusByOneWeek: 'Va à la semaine précédente (Haut) ou suivante (Bas)',
                            moveFocusByOneMonth: 'Passe au mois suivant',
                            moveFocustoStartAndEndOfWeek: 'Va au premier ou au dernier jour de la semaine',
                            returnFocusToInput: "Retourne au champ de texte d'entrée de date",
                            keyboardNavigationInstructions: "Appuyez sur la touche du bas pour intéragir avec le calendrier et choisir une date. Vous pouvez aussi rentrer directement la date dans le champ de texte au format JJ/MM/AAAA",
                            chooseAvailableStartDate: ({ date }) => `Choisir ${date} comme date de début.`,
                            chooseAvailableEndDate: ({ date }) => `Choisir ${date} comme date de fin.`,
                            chooseAvailableDate: ({ date }) => `Choisir ${date}.`,
                            dateIsUnavailable: ({ date }) => `Non disponible ${date}`,
                            dateIsSelected: ({ date }) => `Sélectionné. ${date}`,
                            dateIsSelectedAsStartDate: ({ date }) => `Selectionné comme date de départ. ${date}`,
                            dateIsSelectedAsEndDate: ({ date }) => `Selected comme date de fin. ${date}`,
                        }}
                    />
                }
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center filters-actions my-3">
            <a href="#" role="button" onClick={reset}>Effacer les filtres</a>
            <a href="#" id="apply" role="button" onClick={() => {props.onChange(selectedCats, startDate, endDate, chaine); setVoiceOverFocus($("#filters")[0]) }}>Appliquer <span aria-hidden="true" className="ml-4"></span></a>
        </div>
    </div>
}