import React, { Component, useEffect, useState } from "react";
import "./App.css";
import "react-dates/lib/css/_datepicker.css";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import FolderSpecialIcon from "@material-ui/icons/FolderSpecial";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Typography from "@material-ui/core/Typography";
import Profil from "./components/Profil/Profil";
import Charte from "./components/Charte";
import Programmes from "./components/Programme/Programmes";
import MenuIcon from "@material-ui/icons/Menu";
import { Menu } from "@material-ui/core";
import MenuMobile from "./components/Menu";
import BackButton from "./components/BackButton";
import CharteDetail from "./components/CharteDetail";
import AddToHomeScreenInfo from "./components/AddToHomeScreenInfo";
import ProgrammeDetails from "./components/Programme/ProgrammeDetails";
import ls from "local-storage";
import logo from "./img/menu/img-logo-menu.png";
import menu_ouvrir from "./img/menu/img-menu-ouvrir.png";
import menu_fermer from "./img/menu/img-menu-fermer.png";
import live from "./img/menu/img-sousmenu-live.png";
import replay from "./img/menu/img-sousmenu-replay.png";
import vod from "./img/menu/img-sousmenu-vod.png";
import live_img from "./img/menu/img-cat-live.png";
import films_img from "./img/menu/img-cat-films.png";
import tv_img from "./img/menu/img-cat-tv.png";
import $ from "jquery";
import moment from "moment";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import ProgrammesFilters from "./components/ProgrammesFilters";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect,
} from "react-router-dom";
import FilAriane from "./components/FilAriane";
import Historique from "./components/Historique";
import Classement from "./components/Classement";
import APropos from "./components/APropos";

const Console = (prop) => (
  console[Object.keys(prop)[0]](...Object.values(prop)), null // ➜ React components must return something
);

const TitreProgrammes = (props) => {
  useEffect(() => {
    $("#programmes-titre").focus();
  }, []);
  return (
    <h1 tabIndex="-1" id="programmes-titre">
      Je choisis un programme à évaluer :
    </h1>
  );
};

// const base_url = window.location.origin.includes("http://localhost")
//   ? "http://localhost:3001"
//   : window.location.origin;
const base_url = "https://api.commontv.fr"
const iOSSafari =
  /iP(ad|od|hone)/i.test(window.navigator.userAgent) &&
  /WebKit/i.test(window.navigator.userAgent) &&
  !/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent);
const isAndroid = /android/i.test(window.navigator.userAgent);

var stateLoaded = false;
const chaines_ordre = {
  192: 1, //TF1
  4: 2, //France2
  80: 3, //France 3
  34: 4, //Canal+
  47: 5, //France 5
  118: 6, //M6
  111: 7, //Arte
  445: 8, //C8
  119: 9, //W9
  195: 10, //TMC
  446: 11, //TFX
  444: 12, //NRJ12
  234: 13, //LCP
  78: 14, //France 4
  481: 15, //BFMTV
  226: 16, //CNews
  458: 17, //CStar
  482: 18, //Gulli
  1404: 20, //TF1 Séries Films
  1401: 21, //L'Equipe
  1403: 22, //6ter
  1402: 23, //RMC Story
  1400: 24, //RMC Découverte
  1399: 25, //Chérie 25
  112: 26, //LCO
  2111: 27, //France Info
  529: 33, //France 24
  145: 41, //Paris Première
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charteAcceptee: false,
      profilComplete: false,
      profilIsOpen: false,
      onglet: "live",
      profil: {},
      menuIsOpen: false,
      showAddToHomeScreenInfo:
        (iOSSafari || isAndroid) && !navigator.standalone,
      selectedProgram: null,
      displayFilters: false,
      chaines: [],
      chaine: "",
      date: moment(),
      focused: null,
      from: "accueil",
    };
    //  this.handleChange = this.handleChange.bind(this);
    this.closeProfil = this.closeProfil.bind(this);
  }

  closeProfil = (profil) => {
    this.setState({ profil: profil });
    this.setState({ profilComplete: true });
    //this.setState(prevState =>{ return {profilIsOpen: !prevState.profilIsOpen} });
    console.log(profil.state);
  };

  onMenuClick = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  };
  fetchChaines = async () => {
    const url = base_url + "/api/chaines";
    fetch(url, { method: "GET" })
      .then((response) => response.json())
      .then((results) => {
        // console.log("chainesfetch", results)
        /* results = results.sort(function (a, b) {
          if(a.id in chaines_ordre && b.id in chaines_ordre){
            return chaines_ordre[a.id] - chaines_ordre[b.id]
          }else if(a.id in chaines_ordre){
            return -1;
          }else{
            return 1;
          }
        }
        );*/
        this.setState({ chaines: results });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    //console.log("selected",this.state.selectedProgram)

    return (
      <Router>
        <div
          className="App"
          style={{
            backgroundColor: this.state.menuIsOpen ? "#04316b" : "white",
          }}
        >
          <a className="skip-link" href="#mainContent">
            Aller au contenu principal
          </a>

          <AppBar position="static">
            <Toolbar>
              <Switch>
                <Route exact path="/">
                  <div
                    className={
                      this.state.charteAcceptee && this.state.profilComplete
                        ? "logo_menu"
                        : "logo_menu center"
                    }
                  >
                    <img alt="Plateforme CommonTV" src={logo} />{" "}
                  </div>
                </Route>
                <Route path="/">
                  <Link
                    className={
                      this.state.charteAcceptee && this.state.profilComplete
                        ? "logo_menu"
                        : "logo_menu center"
                    }
                    onClick={() => this.setState({ menuIsOpen: false })}
                    to="/"
                  >
                    <img alt="Revenir à l'accueil" src={logo} />{" "}
                  </Link>
                </Route>
              </Switch>

              {this.state.charteAcceptee && this.state.profilComplete && (
                <nav className="desktop-only main-menu">
                  <ul>
                    <li>
                      <Switch>
                        <Route exact path="/">
                          <Link to="/" className="current" aria-current="page">
                            Programmes
                          </Link>
                        </Route>
                        <Route path="/">
                          <Link to="/">Programmes</Link>
                        </Route>
                      </Switch>
                    </li>
                    <li>
                      <Switch>
                        <Route exact path="/profil">
                          <Link
                            to="/profil"
                            className="current"
                            aria-current="page"
                          >
                            Mon Profil
                          </Link>
                        </Route>
                        <Route path="/">
                          <Link to="/profil">Mon Profil</Link>
                        </Route>
                      </Switch>
                    </li>
                    <li>
                      <Switch>
                        <Route exact path="/historique">
                          <Link
                            to="/historique"
                            className="current"
                            aria-current="page"
                          >
                            Mon historique
                          </Link>
                        </Route>
                        <Route path="/">
                          <Link to="/historique">Mon historique</Link>
                        </Route>
                      </Switch>
                    </li>
                    {/*<li>
                    <Switch>
                      <Route exact path="/classement"><Link to="/classement" className="current" aria-current="page">Classement</Link></Route>
                      <Route path="/"><Link to="/classement">Classement</Link></Route>
                    </Switch>
                  </li>*/}
                    <li>
                      <Switch>
                        <Route exact path="/a_propos">
                          <Link
                            to="/a_propos"
                            className="current"
                            aria-current="page"
                          >
                            À propos
                          </Link>
                        </Route>
                        <Route path="/">
                          <Link to="/a_propos">À propos</Link>
                        </Route>
                      </Switch>
                    </li>
                  </ul>
                </nav>
              )}
              {this.state.charteAcceptee && this.state.profilComplete && (
                <input
                  id="menu-button"
                  className="mobile-only"
                  type="image"
                  src={this.state.menuIsOpen ? menu_fermer : menu_ouvrir}
                  onClick={this.onMenuClick}
                  aria-label="Menu"
                  aria-haspopup="true"
                  aria-expanded={this.state.menuIsOpen}
                />
              )}
            </Toolbar>
            {!this.state.menuIsOpen && this.state.selectedProgram != null && (
              <Switch>
                <Route exact path="/" />
                <Route path="/programme">
                  <FilAriane
                    from={this.state.from}
                    selectedProgramTitle={this.state.selectedProgram.titre}
                    selectedProgramID={this.state.selectedProgram.emission.id}
                  />
                </Route>
              </Switch>
            )}

            {!this.state.menuIsOpen && <BackButton from={this.state.from} />}
          </AppBar>

          {this.state.menuIsOpen && (
            <MenuMobile onClose={() => this.setState({ menuIsOpen: false })} />
          )}
          <main id="mainContent">
            {!this.state.menuIsOpen && (
              <Switch>
                <Route exact path="/">
                  {!this.state.charteAcceptee && (
                    <Charte
                      onClose={() =>
                        this.setState({
                          charteAcceptee: true,
                          profilIsOpen: true,
                        })
                      }
                    />
                  )}
                  {
                    this.state.charteAcceptee && !this.state.profilComplete && (
                      <Redirect to="/profil/mode_communication" />
                    )
                    //  <Redirect to="/profil/materiel_localisation" />
                  }
                  {this.state.charteAcceptee &&
                    this.state.profilComplete &&
                    this.state.showAddToHomeScreenInfo && (
                      <AddToHomeScreenInfo
                        onClose={() =>
                          this.setState({ showAddToHomeScreenInfo: false })
                        }
                      />
                    )}
                  {this.state.charteAcceptee &&
                    this.state.profilComplete &&
                    !this.state.showAddToHomeScreenInfo && (
                      <div id="accueil-content">
                        <div className="titre-onglets">
                          <TitreProgrammes />
                          <div className="onglets" role="tablist">
                            <button
                              role="tab"
                              aria-selected={this.state.onglet === "live"}
                              onClick={() => this.setState({ onglet: "live" })}
                              className={
                                this.state.onglet === "live" ? "active" : ""
                              }
                            >
                              <img alt="" src={live_img} /> Direct
                            </button>
                            {/*<button role="tab" aria-selected={this.state.onglet === "films"} onClick={() => this.setState({ onglet: "films" })} className={this.state.onglet === "films" ? "active" : ""}><img alt="Films" src={films_img} /> Films</button>*/}
                            <button
                              role="tab"
                              aria-selected={this.state.onglet === "replay"}
                              onClick={() =>
                                this.setState({ onglet: "replay" })
                              }
                              className={
                                this.state.onglet === "replay"
                                  ? "replay active"
                                  : "replay"
                              }
                            >
                              <img alt="" src={tv_img} /> Replay
                            </button>
                          </div>
                        </div>

                        {this.state.onglet === "replay" && (
                          <div style={{ clear: "both" }}>
                            <button
                              id="filters"
                              aria-label="Filtres"
                              aria-controls="filtres-programmes"
                              aria-haspopup="true"
                              aria-expanded={this.state.displayFilters}
                              className={`filters-toggle ${
                                this.state.displayFilters
                                  ? "filters-toggle__open"
                                  : ""
                              }`}
                              onClick={() =>
                                this.setState({
                                  displayFilters: !this.state.displayFilters,
                                })
                              }
                            ></button>
                            {this.state.displayFilters && (
                              <ProgrammesFilters
                                chaines={this.state.chaines}
                                chaine={this.state.chaine}
                                endDate={this.state.date}
                                from="accueil"
                                onChange={(
                                  cats,
                                  startDate,
                                  endDate,
                                  selectedChaine
                                ) => {
                                  console.log("startDate apply", endDate);
                                  if (endDate == null) {
                                    alert("La date entrée est incorrecte.");
                                  } else {
                                    this.setState({
                                      date: endDate,
                                      chaine: selectedChaine,
                                      displayFilters: false,
                                    });
                                  }
                                }}
                              />
                            )}

                            <h2 className="historique-day text-left">
                              Les programmes du{" "}
                              <b>{this.state.date.format("DD/MM/YYYY")}</b>
                            </h2>
                          </div>
                        )}

                        <div aria-live="polite">
                          <Programmes
                            chaines_ordre={chaines_ordre}
                            base_url={base_url}
                            date={this.state.date}
                            chaine={this.state.chaine}
                            onglet={this.state.onglet}
                            profil={this.state.profil}
                            handleSelection={(programme) =>
                              this.setState({
                                selectedProgram: programme,
                                from: "accueil",
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                </Route>

                <Route path="/profil">
                  {this.state.charteAcceptee ? (
                    <Profil
                      profilComplete={this.state.profilComplete}
                      profil={this.state.profil}
                      onClose={this.closeProfil}
                    />
                  ) : (
                    <Redirect to="/" />
                  )}
                </Route>

                <Route exact path="/charte">
                  <CharteDetail />
                </Route>

                <Route path="/a_propos">
                  <APropos />
                </Route>

                {this.state.charteAcceptee &&
                  this.state.profilComplete /* && this.state.stateLoaded */ && (
                    <Route
                      path="/programme/:programmeID"
                      render={(props) => (
                        <ProgrammeDetails
                          {...props}
                          base_url={base_url}
                          profil={this.state.profil}
                          programme={
                            this.state.from === "historique"
                              ? null
                              : this.state.selectedProgram
                          }
                          onProfilChange={this.closeProfil}
                          onFetch={(programme) =>
                            this.setState({ selectedProgram: programme })
                          }
                        />
                      )}
                    />
                  )}

                {this.state.charteAcceptee && (
                  <Route path="/historique">
                    {/* Besoin de ces parametres pour gerer le click et la notation à partir d'Historique */}
                    <Historique
                      profil={this.state.profil}
                      handleSelection={(programme) =>
                        this.setState({
                          selectedProgram: programme,
                          from: "historique",
                        })
                      }
                    />
                  </Route>
                )}

                {/*this.state.charteAcceptee && <Route path="/classement">
                
                  <Classement base_url={base_url} profil={this.state.profil} handleSelection={(programme) => this.setState({ selectedProgram: programme, from:"classement" })} />
                </Route>*/}

                <Route path="/">
                  <Redirect to="/" />
                </Route>
              </Switch>
            )}
          </main>
        </div>
      </Router>
    );
  }

  componentWillUpdate(nextProps, nextState) {
    ls("app", JSON.stringify(nextState));
    console.log("state", nextState);
    /*  if (document.getElementById("classement-h1")) {
      from = "classement";
    } else if (document.getElementById("historique-h1")) {
      from = "historique"
    } else if (document.getElementById("programmes-titre")) {
      from = "accueil"
    }*/
    console.log("from", nextState.from);
  }

  componentWillMount() {
    const state = ls("app");

    if (state) {
      let parseState = JSON.parse(state);
      parseState.selectedProgram = null;
      parseState.menuIsOpen = false;
      parseState.onglet = "live";
      parseState.date = moment();
      parseState.focused = null;
      parseState.chaine = "";
      parseState.from = "accueil";
      console.log("state", parseState);
      this.setState(parseState);
    } else {
      /*if (iOSSafari && !navigator.standalone){
        const message ="Vous pouvez installez l'application directement sur l'écran d'accueil de votre téléphone pour un meilleur confort d'utilisation \n"
          + "Pour cela : \n"
          + "- Allez dans le menu partage de votre navigateur \n"
          + "- Selectionnez \"Ajouter à l'écran d'accueil\"";
        alert(message)
      }*/
      stateLoaded = true;
    }

    this.fetchChaines();
  }
}

export default App;
