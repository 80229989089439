import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import {
  Link,
} from "react-router-dom";
import partenaires from '../img/img-logos-partenaires.png'

class APropos extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.myRef.current.focus();
  }

  render() {
    return (
      <DocumentTitle title='À Propos - CommonTV'>
        <div className="charte-details text-left">
          <h1 tabIndex="-1" ref={this.myRef}>À Propos</h1>

          <h2>Qui sommes-nous ?</h2>
          <p>
            <a href="https://www.unanimes.fr/" target="_blank">Unanimes<span className="screen-reader-text"> (Ouvre un nouvel onglet)</span></a>, l’Union des associations nationales pour l’inclusion des malentendants et des sourds a été créée en 2019 à l’initiative des associations nationales de personnes sourdes, malentendantes, devenues-sourdes, sourdaveugles, sourdes avec handicap associé, et de leurs familles, qui ont souhaité s’unir autour de valeurs communes dans le respect de leurs différences.
          </p>

          <p>Nous regroupons aujourd’hui des associations nationales de personnes etde familles et des adhérents individuels.</p>

          <p>Notre ambition : porte une voix commune auprès des pouvoirs publics et des acteurs économiques sur des sujets fédérateurs à l’ensemble de ces publics pour renforcer l’accessibilité de la société et l’émancipation des personnes.</p>

          <p>Unis autour de valeurs communes dans le respect de nos différences, nous agissons ensemble pour l’autonomie et l’inclusion des personnes sourdes dans la société.</p>

          <p>L’accessibilité audiovisuelle est un des sujets sur lesquels nous sommes activement investis.</p>

          <h2>Notre enjeu</h2>

          <p>Parce que l’accessibilité audiovisuelle est un enjeu majeur à l’ensemble des personnes sourdes, malentendantes ou encore sourdaveugles,</p>

          <p>Parce que les chaînes de télévision ont besoin de retours de leurs téléspectateurs quant à la qualité de l’accessibilité de leurs programmes,</p>

          <p>Parce que personne n’est mieux placée que les personnes concernées pour évaluer cette accessibilité,</p>

          <p>Nous avons développé Common TV ®, l’outil collaboratif d’évaluation de la qualité de l’accessibilité audiovisuelle.</p>

          <h2>Un projet né d’une collaboration</h2>

          <p>Common TV ® n’aurait pu voir le jour sans la collaboration et le soutien de :</p>

          <ul>
            <li><a href="https://ambitions.microsoft.fr" target="_blank">Microsoft France<span className="screen-reader-text"> (Ouvre un nouvel onglet)</span></a> et plus particulièrement Philippe Trotin, Directeur de la mission Handicap et E-Accessibilité qui a participé avec Unanimes à l’élaboration du cahier des charges tenant compte de l’accessibilité aux différents publics.</li>
            <li><a href="https://bakhtech.com" target="_blank">Bakhtech<span className="screen-reader-text"> (Ouvre un nouvel onglet)</span></a>, l’agence en informatique sociale, responsable et innovante et ses talentueux développeurs Amath et Aimé accompagnés de leurs collaborateurs. </li>
            <li><a href="https://www.malakoffhumanis.com/" target="_blank">Malakoff-Humanis<span className="screen-reader-text"> (Ouvre un nouvel onglet)</span></a>, et le soutien financier de l’action sociale de Malakoff-Humanis Mutuelle. </li>
            
      

          </ul>
          <img src={partenaires} alt=""></img>
          <h2>Charte</h2>

          <p>L'acceptation de la <Link to="/charte">charte de Common TV®</Link> est obligatoire pour pouvoir utiliser l'outil</p>


        </div>
      </DocumentTitle>
    )
  }
}

export default APropos;