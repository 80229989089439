import { useHistory } from 'react-router-dom'
import React from 'react';
function Button(props) {
  
  let history = useHistory()
 
  return (
    <button onClick={props.onClick ? () => {props.onClick(); (history.push(props.path))} :  ( props.onClickCondition ? () => { if (props.onClickCondition()){ (history.push(props.path))}} : () => (history.push(props.path)))} className={props.className ?  props.className  :  "rbtn"}><div>{props.text}</div><span aria-hidden="true">{'>'}</span></button>
  )
}

export default Button;