import React, {Component} from 'react';
import { useHistory } from "react-router-dom";
import {
  Switch,
  Route,
} from "react-router-dom";
import mascotte from '../img/page02/img-charte-mascotte-desktop.png';
import mascotteMobile from '../img/page02/img-charte-mascotte-mobile.png';
import Snackbar from '@material-ui/core/Snackbar';
import Button from './Button';
import Alert from '@material-ui/lab/Alert';

class Charte extends Component{
  constructor(props){
    super(props);
    this.state = {
      acceptee: false,
      warningOpen: false,
    }
    this.save = this.save.bind(this);
    this.voirCharte = this.voirCharte.bind(this);
    this.showWarning = this.showWarning.bind(this);
  }
  save(){
  	this.setState({acceptee:true});
  	this.props.onClose();
  }

  voirCharte(){
    const history =  useHistory()
  }

  showWarning(event){
    event.preventDefault();
    this.setState({warningOpen:true});
  }

  render (){
  
    return (
      <Switch>
        
        <Route exact path="/">
          <div className="charte content">
            <img className="mascotte-mobile" alt="" src={mascotteMobile}></img>
            <h1 id="charte-title">Bonjour à vous !</h1>

            <p>Avant toute chose, il va falloir accepter la charte</p>

           <Button className="rbtn beige"  path="/charte" text="Charte d'utilisation"/>

            <button onClick={this.save} className="rbtn"><div>J'accepte et je continue <span aria-hidden="true" data-icon=">"></span></div></button>
  
            <a href="#" onClick={this.showWarning}>Je refuse</a>

            <Snackbar open={this.state.warningOpen} autoHideDuration={6000} onClose={()=>this.setState({warningOpen:false})}>
              <Alert variant="filled" role="alert" onClose={()=>this.setState({warningOpen:false})} severity="warning">
                Vous devez accepter la charte pour pouvoir utiliser l'application.
              </Alert>
	          </Snackbar>
            
          </div>
          <div className="bottom-bar"></div>
          <img className="mascotte-desktop" alt="" src={mascotte}></img>
          
        </Route>
      </Switch>
      /* <MuiAlert elevation={6} variant="filled" severity="warning" >
                <span role="alert">Vous devez accepter la charte pour pouvoir utiliser l'application</span>
              </MuiAlert>*/
   /* <Dialog open={this.props.open} aria-labelledby="customized-dialog-title" >
        <DialogTitle id="customized-dialog-title" onClose={this.props.onClose}>
          Charte
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
            auctor fringilla.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.save} color="primary">
            Accepter
          </Button>
        </DialogActions>
      </Dialog>*/)
  }
}

export default Charte;