import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
  } from "react-router-dom"


function FilAriane(props){
    let history = useHistory()

    function goBack(number,event){
        event.preventDefault();
        for (var i = 0; i < number; i++){
          history.goBack()
        }
      }
    
    return (
        <nav aria-label="Fil d'Arianne" id="breadcrumb">
            <ol >
                <li>
                    <Link to="/">Programmes </Link><span aria-hidden="true">{'>'}</span>
                </li>
                <Switch>
                    <Route exact path="/programme/:programmeID">
                        <li><a>{props.selectedProgramTitle}</a></li>
                    </Route>

                    <Route exact path="/programme/:programmeID/evaluation/:accessibilite">
                        <li><Link to={"/programme/"+props.selectedProgramID}>{props.selectedProgramTitle} </Link><span aria-hidden="true">{'>'}</span></li>
                        <li><a aria-current="page">Évaluation</a></li>
                    </Route>
                    <Route exact path="/programme/:programmeID/evaluation/:accessibilite/details">
                        <li><Link to={"/programme/"+props.selectedProgramID}>{props.selectedProgramTitle} </Link><span aria-hidden="true">{'>'}</span></li>
                        <li><a href="#" onClick={(e)=>goBack(1,e)}>Évaluation</a><span aria-hidden="true">{'>'}</span></li>
                        <li><a aria-current="step">Améliorations</a></li>
                    </Route>

                    <Route exact path="/programme/:programmeID/evaluation/:accessibilite/fin">
                        <li><Link to={"/programme/"+props.selectedProgramID}>{props.selectedProgramTitle} </Link><span aria-hidden="true">{'>'}</span></li>
                        <li><a>Évaluation</a><span aria-hidden="true">{'>'}</span></li>
                        <li><a aria-current="step">Finalisation</a></li>
                    </Route>
             
                    <Route exact path="/programme/:programmeID/evaluation/:accessibilite/details/fin">
                        <li><Link to={"/programme/"+props.selectedProgramID}>{props.selectedProgramTitle} </Link><span aria-hidden="true">{'>'}</span></li>
                        <li><a>Évaluation</a><span aria-hidden="true">{'>'}</span></li>
                        <li><a>Améliorations</a><span aria-hidden="true">{'>'}</span></li>
                        <li><a aria-current="step">Finalisation</a></li>
                    </Route>

                    <Route path="/programme/:programmeID/evaluation/:accessibilite/profil">
                        <li><Link to={"/programme/"+props.selectedProgramID}>{props.selectedProgramTitle} </Link><span aria-hidden="true">{'>'}</span></li>
                        <li><a>Évaluation</a><span aria-hidden="true">{'>'}</span></li>
                        <li><a>Profil</a><span aria-hidden="true">{'>'}</span></li>
                    </Route>
                </Switch>
            </ol>
            <Switch>

            </Switch>

        </nav>
    )
}

export default FilAriane;