import React, {Component} from 'react';
import super_picto from '../../img/emoticones/img-picto-super.png'
import mascotte from '../../img/page10/img-mascotte-programme-merci.png';
import mascotte_mobile from '../../img/page10/img-mascotte-programme-merci-mobile.png';
import Button from '../Button';


class EvaluationFin extends Component{
  constructor(props){
    super(props);
    this.state = {
    	
    }
    this.myRef = React.createRef();
  }

  componentDidMount(){
     if (this.myRef.current != null){
        this.myRef.current.focus();
      }
  }

  render(){
      return(
        <div className="evaluation-fin">
            <div>
                <img src={super_picto} alt="" className="title_picto"/>
                <h1 tabIndex="-1" ref={this.myRef}>Merci !</h1>
                <p>Vos retours sont précieux</p>
                <Button path="/" text="Évaluer un autre programme"/>
                <img className="mascotte-evaluation" alt="" src={mascotte}></img>
                <img className="mascotte-evaluation-mobile" alt="" src={mascotte_mobile}></img>
            </div>
           
        </div>
      )
  }
  
}

export default EvaluationFin;