import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuMobile = (props) => {
    const routes = {
        '/': 'Programmes',
        '/profil': 'Mon Profil',
        '/historique': 'Mon historique',
        /*'/classement': 'Classement',*/
        '/a_propos':'À propos',
        
    }

    return <nav className="list-group menu-page">
        { Object.keys(routes).map(path => <NavLink 
            exact 
            to={path} 
            key={path} 
            activeClassName="current" 
            className={`list-group-item list-group-item-action menu-${routes[path].toLowerCase().replace(' ', '-')}`} 
            aria-current="page" 
            onClick={props.onClose}
        >
            <span>{ routes[path] }</span>
        </NavLink> )}
    </nav>
    
}

export default MenuMobile;