import React, {Component} from 'react';
import ss_picto from '../../img/pictos-type-handicap/img-picto-soustitres.png'
import lsf_picto from '../../img/pictos-type-handicap/img-picto-lsf.png'
import ad_picto from '../../img/pictos-type-handicap/img-picto-audisescription.png'
import {
  Route} from "react-router-dom";



class EvaluationTitle extends Component{
  constructor(props){
    super(props);
    this.state = {
    	
    }
    this.myRef = React.createRef();
  }

  componentDidMount(){
   /* if (this.myRef.current != null){
        this.myRef.current.focus();
      }*/
  }

  render(){
      return(
        <div className="evaluation-title desktop-only">
            <Route  path="/programme/:programmeID/evaluation/sous_titrage">
                <img alt="" src={ss_picto}/>
                <div className="evaluation-text">
                    <h2 className="bold" tabIndex="-1" ref={this.myRef}>Sous-titrage</h2>
                    <p>Evaluation</p>
                </div>
                
            </Route>
            <Route  path="/programme/:programmeID/evaluation/lsf">
                <img alt="" src={lsf_picto}/>
                <div className="evaluation-text">
                    <h2 className="bold" tabIndex="-1" ref={this.myRef}>LSF</h2>
                    <p>Evaluation</p>
                </div>
                
            </Route>
            <Route  path="/programme/:programmeID/evaluation/:accessibilite/audio_description">
                <img alt="" src={ad_picto}/>
                <div className="evaluation-text">
                    <h2 className="bold" tabIndex="-1" ref={this.myRef}>Audio Description</h2>
                    <p>Evaluation</p>
                </div>
                
            </Route>
        </div>
      )
  }
  
}

export default EvaluationTitle;