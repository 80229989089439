import React, { Component } from 'react';

import Button from '../Button';
// import reception_picto from '../../img/emoticones/img-picto-mode-reception.png'
import tnt_picto from '../../img/pictos-fond-choix/img-fond-tnt.png'
import internet_picto from '../../img/pictos-fond-choix/img-fond-box-internet.png'
import animateScrollTo from 'animated-scroll-to';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import $ from 'jquery';

class ProfilModeReception extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",
            fai: props.fai || "",
            box: props.box || "",
        }
        this.myRef = React.createRef();
        this.setValue = this.setValue.bind(this);
        this.hasScrolled = false;
    }


    componentDidMount() {
        this.myRef.current.focus();
        $(window).scrollTop(0);

    }

    componentDidUpdate() {
        if (document.getElementsByClassName('rbtn').length > 0 && !this.hasScrolled) {
            animateScrollTo(document.querySelector('.rbtn'));
            this.hasScrolled = true
        }
    }

    setValue(event, value) {
        event.preventDefault();
        this.setState({ value: value })

    }

    isFormValid(){

        switch(this.state.value) {
            case "":
              return false
            case "Box internet":
              if(this.state.fai=="molotov" || this.state.fai=="salto"){
                  return true
              }else{
                  return this.state.box != ""
              }
            default:
              return true
          }
    }


    render() {

        const box_internet = this.props.box_internet
        return (
            <div>
                <div className="content reception">
                    {/* <img src={reception_picto} alt="" className="title_picto"/> */}
                    <h1 tabIndex="-1" ref={this.myRef}>Mon mode de réception</h1>

                    <p id="rg1_label">Par quel biais je regarde le plus la TV ?</p>
                    <div className="cards" role="radiogroup" aria-labelledby="rg1_label">
                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "TNT")}
                                aria-checked={this.state.value == "TNT"}>
                                <div className={this.state.value == "TNT" ? "card active" : "card"}>
                                    <img src={tnt_picto} alt="" />
                                    <p>TNT</p>
                                </div>
                            </a>
                        </div>

                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "Box internet")}
                                aria-checked={this.state.value == "Box internet"}>
                                <div className={this.state.value == "Box internet" ? "card active" : "card"}>
                                    <img src={internet_picto} alt="" />
                                    <p>Box internet</p>
                                </div>
                            </a>
                        </div>

                        {/*  <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "Satellite")}
                                aria-checked={this.state.value == "Satellite"}>
                                <div className={this.state.value == "Satellite" ? "card active" : "card"} >
                                    <img src={satellite_picto} alt="" />
                                    <p>Satellite</p>
                                </div>
                            </a>
                        </div>

                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "Cable")} aria-checked={this.state.value == "Cable"}>
                                <div className={this.state.value == "Cable" ? "card active" : "card"} >
                                    <img src={cable_picto} alt="" className="width-100" />
                                    <p>Câble</p>
                                </div>
                            </a>
                        </div>*/}

                    </div>
                    {this.state.value === "Box internet" &&
                        <div>
                        <p>Quel est votre fournisseur internet ?</p>
                        <FormControl variant="outlined" >
                            <InputLabel id="fai-label">Fournisseur</InputLabel>
                            <Select
                                labelId="fai-label"
                                id="fai-select-outlined"
                                value={this.state.fai}
                                onChange={(event) => this.setState({ fai: event.target.value,box:"" })}
                                label="Fournisseur"
                            >
                                {Object.keys(box_internet).map(fai_id =>
                                    <MenuItem value={fai_id}>{box_internet[fai_id]["name"]}</MenuItem>
                                )}
                            </Select>

                        </FormControl>
                        </div>
                    }

                    {this.state.value === "Box internet" && this.state.fai!="molotov" && this.state.fai!="salto" && box_internet[this.state.fai]  &&
                        <div>
                        <p>Quelle box internet utilisez vous ?</p>
                            <FormControl variant="outlined" >
                                <InputLabel id="box-label">Box Internet</InputLabel>
                                <Select
                                    labelId="box-label"
                                    id="box-select-outlined"
                                    value={this.state.box}
                                    onChange={(event) => this.setState({ box: event.target.value })}
                                    label="Box Internet"
                                >
                                    {box_internet[this.state.fai]["box"].map(box =>
                                        <MenuItem value={box}>{box}</MenuItem>
                                    )}
                                    <MenuItem value="Je ne sais pas">Je ne sais pas</MenuItem>
                                </Select>

                            </FormControl>
                        </div>
                    }
                    {this.isFormValid() && !this.props.match &&
                        <Button path="/profil/materiel_localisation" className="mobile-only rbtn" onClick={() =>  this.props.onClose(this.state.value,this.state.fai,this.state.box)}  text="Étape suivante" />
                    }
                    {this.isFormValid() && this.props.match &&
                        <Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/profil/materiel_localisation"} className="mobile-only rbtn" onClick={() =>  this.props.onClose(this.state.value,this.state.fai,this.state.box)} text="Étape suivante" />
                    }
                    {!this.isFormValid() &&
                        <p className="screen-reader-text">Vous devez remplir tous les champs pour pouvoir continuer</p>
                    }
                </div>
                <div className="bottom-bar">
                    {this.isFormValid() && !this.props.match &&
                        <Button path="/profil/materiel_localisation" className="desktop-only rbtn" onClick={() => this.props.onClose(this.state.value,this.state.fai,this.state.box)} text="Étape suivante" />
                    }

                    {this.isFormValid() && this.props.match &&
                        <Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/profil/materiel_localisation"} className="desktop-only rbtn" onClick={() =>  this.props.onClose(this.state.value,this.state.fai,this.state.box)} text="Étape suivante" />
                    }
                </div>
            </div>

        )
    }
}

export default ProfilModeReception;