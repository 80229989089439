import React, { Component } from 'react';

import Button from '../Button';
import DocumentTitle from 'react-document-title';
import ProfilModeCommunication from './ProfilModeCommunication'
import ProfilModeReception from './ProfilModeReception'
import ProfilMaterielLocalisation from './ProfilMaterielLocalisation'

import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

class Profil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			handicaps: [],
			mode_communication: "",
			mode_acces: "",
			fai: "",
			box: "",
			materiel: "",
			region: "",
			warningOpen: false,
		}
		this.checkPasVide = this.checkPasVide.bind(this);
		this.addRemoveHandicap = this.addRemoveHandicap.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.myRef = React.createRef();

	}
	componentDidMount() {
		if (this.myRef.current != null) {
			this.myRef.current.focus();
		}
		console.log("props profil", this.props.profil);
		console.log("profil complete", this.props.profilComplete);
		this.setState(this.props.profil)
	}
	checkPasVide() {
		if (this.state.mode_acces !== "" && this.state.materiel !== "" && this.state.region !== "") {
			this.props.onClose(this.state);
		}
		else {
			this.setState({ warningOpen: true });
		}

	}

	addRemoveHandicap(event, handicap) {
		event.preventDefault();
		if (this.state.handicaps.includes(handicap)) {
			this.setState({ handicaps: this.state.handicaps.filter(e => e !== handicap) });
		} else {
			var array1 = [handicap]
			this.setState({ handicaps: this.state.handicaps.concat(array1) });
		}
	}

	handleChange(materiel,localisation){
		this.setState({ materiel: materiel, region: localisation }, () => {
			console.log("state updated to", this.state)
		})
	}

	render() {
		/*const box_internet = {
            free: {
                name: "Free",
                box: [{ id: "revolution", name: "Freebox Révolution" },
                { id: "mini", name: "Freebox Mini 4k" },
                { id: "pop", name: "Freebox Pop" },
                { id: "delta", name: "Freebox Delta" },
                { id: "delta_s", name: "Freebox Delta S" }]
            },
            orange: {
                name: "Orange",
                box: [{ id: "livebox", name: "Livebox" },
                { id: "livebox_fibre", name: "Livebox Fibre" },
                { id: "livebox_up", name: "Livebox Up" },
                { id: "livebox_up_fibre", name: "Livebox Up Fibre" }]
            }
		}*/
		
		const box_internet = {
            sfr: {
				name: "SFR",
				box: ["Box Plus", "Box SFR 8"]
			},
			red: {
				name: "Red by SFR",
				box: ["Red Box"]
			},
            orange: {
                name: "Orange",
                box: ["Livebox 5" , "Livebox Play" , "Livebox 2" ,"Livebox 4" ]
			},
			sosh: {
				name: "Sosh by Orange",
				box: ["Boîte Internet"]
			},
			free: {
                name: "Free",
                box: ["Freebox Révolution" , "Freebox Mini 4k", "Freebox Pop" , "Freebox Delta" , "Freebox Delta S" ]
            },
			
			bouygues: {
				name: "Bouygues Telecom",
				box: ["Bbox Sensation","Bbox Miami","Bbox Fit", "Bbox Must","Bbox Ultym","4GBox"]
			},
			poste:{
				name:"La Poste Mobile",
				box: ["Box La Poste Mobile", "Box Plus", "Box SFR 8"]
			},
			coriolis:{
				name:"Coriolis Télécom",
				box: ["Coriolis Box"]
			},
			molotov:{
				name:"Molotov",
				box: []
			},
			salto:{
				name:"Salto",
				box:[]
			}	
        }
		return (
			<div className="profil">
				{<DocumentTitle title='Profil - CommonTV'>
					<Switch>
						<Route path="/profil/mode_communication">
							<ProfilModeCommunication profilComplete={this.props.profilComplete} value={this.state.mode_communication} onClose={(value) => { this.setState({ mode_communication: value }); }} />
						</Route>

						<Route path="/programme/:programmeID/evaluation/:accessibilite/profil/mode_communication" render={(props) => <ProfilModeCommunication{...props} value={this.state.mode_communication} profilComplete={this.props.profilComplete} onClose={(value) => { this.setState({ mode_communication: value }); }} />} />

						<Route path="/profil/mode_reception">
							<ProfilModeReception box_internet={box_internet} fai={this.state.fai} box={this.state.box} value={this.state.mode_acces} onClose={(value,fournisseur,box_internet) => { this.setState({ mode_acces: value, fai:fournisseur,box:box_internet }); }} />
						</Route>

						<Route path="/programme/:programmeID/evaluation/:accessibilite/profil/mode_reception" render={(props) => <ProfilModeReception{...props} value={this.state.mode_acces} box_internet={box_internet} fai={this.state.fai} box={this.state.box} onClose={(value,fournisseur,box_internet) => { this.setState({ mode_acces: value, fai:fournisseur,box:box_internet }); }}  />} />

						<Route path="/profil/materiel_localisation" >
							<ProfilMaterielLocalisation  materiel={this.state.materiel} localisation={this.state.region} handleChange={this.handleChange} onClose={() => this.props.onClose(this.state)} />
						</Route>

						<Route path="/programme/:programmeID/evaluation/:accessibilite/profil/materiel_localisation" render={(props) => <ProfilMaterielLocalisation{...props} materiel={this.state.materiel} localisation={this.state.region} handleChange={this.handleChange} onClose={() => this.props.onClose(this.state)}/>} />


						<Route exact path="/profil">
							<h1 tabIndex="-1" ref={this.myRef}>Mon profil</h1>
							<p>Mode de communication : {this.state.mode_communication}</p>
							<p>Mode d'accès : {this.state.mode_acces}</p>
							<p>Matériel privilégié : {this.state.materiel}</p>
							<p>Région : {this.state.region}</p>
							{this.state.fai!==""&&
								<p>Fournisseur : {box_internet[this.state.fai]["name"]}</p>
							}
							{this.state.box!==""&&
								<p>Box internet : {this.state.box}</p>
							}
							<Button path="/profil/mode_communication" text="Modifier mes informations" />
						</Route>

						<Route exact path="/programme/:programmeID/evaluation/:accessibilite/profil">
							<h1 tabIndex="-1" ref={this.myRef}>Vos informations de profil sont elles toujours exactes ?</h1>
							<p>Mode de communication : {this.state.mode_communication}</p>
							<p>Mode d'accès : {this.state.mode_acces}</p>
							{this.state.fai!==""&&
								<p>Fournisseur : {box_internet[this.state.fai]["name"]}</p>
							}
							{this.state.box!==""&&
								<p>Box internet : {this.state.box}</p>
							}
							<p>Matériel privilégié : {this.state.materiel}</p>
							<p>Région : {this.state.region}</p>
							{this.props.match &&
								<div><Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/fin"} text="Oui, envoyer mon vote" onClick={() => this.props.onClose(this.state)} />
									<Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/profil/mode_communication"} className="rbtn red" text="Non, modifier mon profil avant validation" />
								</div>
							}
						</Route>
					</Switch>
				</DocumentTitle>
				}
			</div>
		)
	}
}

export default Profil;