import React, {Component} from 'react';
import bon_picto from '../../img/menu/img-bon.png'
import absent_picto from '../../img/menu/img-absent.png'
import mauvais_picto from '../../img/menu/img-mauvais.png'
import ss_picto from '../../img/pictos-type-handicap/img-picto-soustitres.png'
import lsf_picto from '../../img/pictos-type-handicap/img-picto-lsf.png'
import ad_picto from '../../img/pictos-type-handicap/img-picto-audisescription.png'
import {
  Route,
  Link,
} from "react-router-dom";



class Evaluation extends Component{
  constructor(props){
    super(props);
    this.state = {
      accessibilite: props.match.params.accessibilite,
      id: props.match.params.programmeID
    }
    this.myRef = React.createRef();
  }

  componentDidMount(){
    if (this.myRef.current != null){
      this.myRef.current.focus();
    }
  }

  render(){

      const details_url =  this.state.accessibilite === "audio_description"  ?  "/programme/" + this.state.id + "/evaluation/" + this.state.accessibilite + "/profil" : "/programme/" + this.state.id + "/evaluation/" + this.state.accessibilite + "/details"; 
      const fin_url = "/programme/" + this.state.id + "/evaluation/" + this.state.accessibilite + "/fin"; 
      const profil_url = "/programme/" + this.state.id + "/evaluation/" + this.state.accessibilite + "/profil"; 
      return(<div>
                <div className="mobile-only">
                  <div className="evaluation-title-mobile row">
                    <Route  path="/programme/:programmeID/evaluation/sous_titrage">
                      <img alt="" src={ss_picto}/>
                      <div className="evaluation-text">
                          <h2 className="bold" tabIndex="-1">Sous-titrage</h2>
                          <p>Evaluation</p>
                      </div>
                    </Route>
                    <Route  path="/programme/:programmeID/evaluation/lsf">
                    <img alt="" src={lsf_picto}/>
                        <div className="evaluation-text">
                            <h2 className="bold" tabIndex="-1">LSF</h2>
                            <p>Evaluation</p>
                        </div>
            
                      
                    </Route>
                    <Route  path="/programme/:programmeID/evaluation/audio_description">
                      <img alt="" src={ad_picto}/>
                        <div className="evaluation-text">
                            <h2 className="bold" tabIndex="-1">Audio Description</h2>
                            <p>Evaluation</p>
                        </div>
                    </Route>
                  </div>

                  <hr/>
                </div>

                <div className="evaluation">
                {this.state.accessibilite === "sous_titrage" && <p className="bold text-left" tabIndex="-1" ref={this.myRef}>Le sous-titrage est :</p>}
                {this.state.accessibilite === "lsf" && <p className="bold text-left" tabIndex="-1" ref={this.myRef}>L'interprétation en langue des signes est :</p>}
                {this.state.accessibilite === "audio_description" && <p className="bold text-left" tabIndex="-1" ref={this.myRef}>L'audio-description est :</p>}
                <Link onClick={()=> this.props.onClick("bon")  } to={profil_url} className="evaluation_link green rounded row first" >
                  <img alt="" src={bon_picto}/>
                  <div className="evaluation_link_text">
                    <p className="bold">
                      {this.state.accessibilite === "sous_titrage" ? "Bon" : "Bonne"}
                    </p>
                  </div>
                  <span aria-hidden="true">{'>'}</span>
                  </Link>
                  
                  <Link onClick={()=> this.props.onClick("mauvais")  } to={details_url} className="evaluation_link red rounded row" >
                  <img alt="" src={mauvais_picto}/>
                  <div className="evaluation_link_text"><p className="bold">À améliorer</p></div>
                  <span aria-hidden="true">{'>'}</span>


                  </Link>


                  {((this.state.accessibilite=="lsf"&& this.props.has_lsf) || (this.state.accessibilite=="sous_titrage"&& this.props.has_ss)) && <Link onClick={()=> this.props.onClick("absent") } to={profil_url} className="evaluation_link rounded gray row last">
                    <img alt="" src={absent_picto}/>
                    <div className="evaluation_link_text"><p className="bold">{this.state.accessibilite === "sous_titrage" ? "Absent" : "Absente"}</p>
                    
                    </div>
                    <span aria-hidden="true">{'>'}</span>
                  </Link>}

                  </div>
                  

      </div>)
  }
  
}

export default Evaluation;