import 'focus-visible';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import $ from 'jquery'; 
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


(function (original) {
  console.enableLogging = function () {
      console.log = original;
  };
  console.disableLogging = function () {
      console.log = function () {};
  };
})(console.log);
//disable log on prod
if (!window.location.origin.includes("http://localhost")){
  console.disableLogging()
}

$(document).ready(function() {
  console.log( 'Test');
  var deferredPrompt;
  
  
  window.addEventListener('beforeinstallprompt', function(e) {
    console.log('beforeinstallprompt Event fired');
    e.preventDefault();

    // Stash the event so it can be triggered later.
  
      // Show the install prompt.
     e.prompt();
     // Log the result
     e.userChoice.then((result) => {
         console.log('👍', 'userChoice', result);
         // Reset the deferred prompt variable, since
         // prompt() can only be called once.
    
         // Hide the install button.
     });


    return false;
  });
 
  
});

$('#root').hide();
ReactDOM.render(<App/>, document.getElementById('root'));

const iOSSafari = /iP(ad|od|hone)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));
const shouldShowSplashcreen = iOSSafari && !navigator.standalone;

// on masque le splashcreen au bout de 2s;
setTimeout(function() {
  $('.splashscreen').remove();
  $('#root').show();
}, shouldShowSplashcreen ? 3000 : 0);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
