import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import $ from 'jquery';


import Button from '../Button';
import pin from '../../img/pin.png'
import Evaluation from './Evaluation';
import EvaluationTitle from './EvaluationTitle';
import EvaluationDetails from './EvaluationDetails';
import Profil from '../Profil/Profil'
import ss_picto from '../../img/pictos-fond-choix/img-fond-soustitres.png'
import lsf_picto from '../../img/pictos-fond-choix/img-fond-lsf.png'
import ad_picto from '../../img/pictos-fond-choix/img-fond-audiodescription.png'
import bon from '../../img/emoticones/bon.png'
import moyen from '../../img/emoticones/neutre.png'
import mauvais from '../../img/emoticones/mauvais.png'
import non_accessible from '../../img/emoticones/absent.png'
import EvaluationFin from './EvaluationFin';
import ErreurVote from './ErreurVote';
import DocumentTitle from 'react-document-title';
import ls from 'local-storage';
import aucun_visuel from '../../img/aucun-visuel.png'

class ProgrammeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emissionID: props.match.params.programmeID,
      programme: this.props.programme,
      accessibilite: props.match.params.accessibilite || "",
      evaluation: "",
      precision: [],
      votes_historique: [],
      vote_state: "not_sent",
      profil: this.props.profil,
      finalement_accessible: false,
      rating_qual_lsf: 5
      //precision_lsf: [],
    }
    this.myRef = React.createRef();
  }


  componentDidMount() {
    console.log("id", this.state.id)
    console.log("program", this.props.programme)
    if (this.myRef.current != null) {
      this.myRef.current.focus();
    }

    var historique = ls('historique');
    if (historique) {
      historique = JSON.parse(historique)
      const votes_array = []
      for (const [key, votes] of Object.entries(historique)) {
        console.log(key, votes);

        for (const vote of votes) {
          if (vote.emissionID === this.props.match.params.programmeID) {
            const dateArray = key.split("-")
            const date = [dateArray[2], dateArray[1], dateArray[0]].join('/');
            vote["date"] = date
            votes_array.push(vote)

          }
        }

      }
      console.log("votes faits", votes_array)
      this.setState({ votes_historique: votes_array });



    }

    /* if (this.props.programme == null) {
       this.fetchProgramm();
     }*/
    this.fetchProgramm();
  }

  fetchProgramm() {
    console.log("fetching")
    const url = this.props.base_url + "/api/program?programID=" + this.state.emissionID
    console.log(url)
    fetch(url, { method: "GET" })
      .then(response => response.json())
      .then((result) => {

        if (result.length > 0) {
          console.log("program fetch", result[0])
          this.props.onFetch(result[0]);
          this.setState({ programme: result[0] });
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeCheckBox(element) {
    var array = [...this.state.precision];
    var index = this.state.precision.indexOf(element);
    if (index !== -1) { // Si élément déjà coché
      array.splice(index, 1);
      this.setState({ precision: array });
    } else { // Sinon ajoute
      this.setState(prevState => ({ precision: [...prevState.precision, element] }));
    }
  }

  handleSSClick(event) {
    const has_ss = this.state.programme.attributs.indexOf("Sous-titrage Télétexte") !== -1;
    console.log("A voté", this.checkVote("ss_titre"))
    var continue_vote = true;
    if(!has_ss && !this.checkVote("ss_titre")){
        continue_vote =  window.confirm("Le sous-titrage a été déclaré comme non présent sur ce programme. Voulez vous quand même procéder à l'évaluation ?")
    }else if (this.checkVote("ss_titre")){
      continue_vote = window.confirm("Vous avez déjà évalué le sous-titrage de ce programme. Êtes vous sûr de vouloir l'évaluer à nouveau ?")
    }
    if (continue_vote){
      this.setAccessibiliteEvaluee("ss_titre")
      
    }else{
      event.preventDefault();
    }

  
  }

  handleLSFClick(event) {
  
    if (!this.checkVote("lsf") || window.confirm("Vous avez déjà évalué l'interprétation en langue des signes de ce programme. Êtes vous sûr de vouloir l'évaluer à nouveau ?")) {
      this.setAccessibiliteEvaluee("lsf")
    }else {
      event.preventDefault();
    }
  }

  /*
  changeCheckBoxLSF(element){
    var array = [...this.state.precision_lsf];
    var index = this.state.precision_lsf.indexOf(element);
    if(index!==-1){ // Si élément déjà coché
      array.splice(index, 1);
      this.setState({precision_lsf: array});
    }else { // Sinon on l'ajoute
      this.setState(prevState => ({ precision_lsf: [...prevState.precision_lsf, element] }));
    }
  }*/

  setAccessibiliteEvaluee(accessibilite) {
    if (accessibilite === "ss_titre") {
      this.setState({ accessibilite: "ss_titre", precision: [], vote_state: "not_sent" }) //, precision_lsf: []
    } else if (accessibilite === "lsf") {
      this.setState({ accessibilite: "lsf", precision: [], vote_state: "not_sent" })
    } else if (accessibilite === "audio_description") {
      this.setState({ accessibilite: "audio_description", precision: [], vote_state: "not_sent" }) //, precision_lsf: []
    }
  }

  setEvaluation(evaluation) {
    console.log("Setevaluation : ", evaluation);
    if (evaluation === "bon" || evaluation === "absent") {
      this.setState({ evaluation: evaluation, precision: [], rating_qual_lsf : 10 }); //, precision_lsf: []
      //au cas où l'utilisateur est revenu en arrière après avoir commencé à remplir la partie "instatisfaisante"
    } else {
      this.setState({ evaluation: evaluation });
    }

  }

  sendVote() {
    this.setState({
      vote_state: "sending"
    })
    var dataVote = {};
    Object.assign(dataVote, this.state)
    delete dataVote['votes_historique'];
    delete dataVote['vote_state'];
    delete dataVote['profil'];
    //delete dataVote['precisionWindow'];
    //let data = new FormData();
    //data.append( "json", JSON.stringify( {...dataVote, ...this.props.profil} ) );
    console.log('CALL API BACK - Send Vote : ', { ...dataVote, ...this.state.profil });
    fetch(this.props.base_url + '/api/vote/',//window.location.origin+
      {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...dataVote, ...this.state.profil })
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        //this.setState({isLoading: false});
        //this.setState({precisionWindow:false});
        //this.props.disableButton();
        var historique = ls('historique');
        let d = new Date()
        let month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          //day = "7",
          year = '' + d.getFullYear();
        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        var datehistorique = [year, month, day].join('-');
        if (historique) {
          console.log("historique ls", historique)
          historique = JSON.parse(historique)
          let keys = Object.keys(historique)
          if (keys.length > 9 && !(datehistorique in historique)) { //On garde seulement 10 jours max dans l'historique
            keys.sort(function (first, second) {
              return first < second ? -1 : 1;
            });
            delete historique[keys[0]]
          }
        } else {
          historique = {}


        }

        if (!(datehistorique in historique)) {
          historique[datehistorique] = []
        }

        dataVote.rating = response.rating
        historique[datehistorique].push(dataVote)
        console.log("historique", historique)
        ls('historique', JSON.stringify(historique));
        this.setState(prevState => ({
          votes_historique: [...prevState.votes_historique, dataVote],
          vote_state: "sent"
        }))
        this.fetchProgramm();
      }).catch((error) => {
        console.log("error", error);
        this.setState({
          vote_state: "error"
        })
      });;
  }

  componentWillUpdate(nextProps, nextState) {
    console.log("state updated to", nextState);
  }

  checkVote(mode_accessibilite) {
    for (const vote of this.state.votes_historique) {
      if (vote.accessibilite === mode_accessibilite) {
        return true;
      }
    }
    return false
  }

  getPicto(note) {
    if (note < 5) return mauvais
    else if (note < 8) return moyen
    else return bon
  }

  getUserRatings(){
    var ratings = {}
    for (const vote of this.state.votes_historique) {
       if(vote.hasOwnProperty("rating")){
        ratings[vote.accessibilite] = vote.rating
       }
       if(vote.accessibilite=="lsf" && vote.hasOwnProperty("rating_qual_lsf")){
         ratings.lsf_qual = vote.rating_qual_lsf
       }
    }
    console.log("userRatngs", ratings)
    return ratings
  }





  render() {

    if (this.state.programme != null) {
      const titre = this.state.programme.titre;
      const date = this.state.programme.heure;
      const rating = Math.round(this.state.programme.rating * 100) / 100;
      const photo_url = this.state.programme.photo_url.length !== 0 && this.state.programme.photo_url;
      const genre = this.state.programme.genre;
      const nb_votes = this.state.programme.nb_votes;
      const nb_votes_stt = this.state.programme.nb_votes_stt;
      const rating_stt = this.state.programme.rating_stt;
      const nb_votes_lsf = this.state.programme.nb_votes_lsf;
      const rating_lsf_tech = this.state.programme.rating_lsf;
      const rating_lsf_qual = this.state.programme.rating_lsf_qual == undefined ? null : this.state.programme.rating_lsf_qual;
      const userRatings = this.getUserRatings()
      const has_audio_description = this.state.programme.attributs.indexOf("Audiodescription") !== -1;
      const has_lsf = true // this.state.programme.attributs.indexOf("Langage des signes") !== -1;
      const has_ss = this.state.programme.attributs.indexOf("Sous-titrage Télétexte") !== -1;
      var a_vote_ss = this.checkVote("ss_titre");
      var a_vote_lsf = this.checkVote("lsf");
      var a_vote_ad = this.checkVote('audio_description');
      const accessibilite_check = (this.state.accessibilite === "lsf" /*&& has_lsf*/) || (this.state.accessibilite === "ss_titre" && has_ss) || (this.state.accessibilite === "audio_description" && has_audio_description)


      const eval_st_path = "/programme/" + this.state.emissionID + "/evaluation/sous_titrage";
      const eval_lsf_path = "/programme/" + this.state.emissionID + "/evaluation/lsf";
      const eval_ad_path = "/programme/" + this.state.emissionID + "/evaluation/audio_description";
      const date_debut = window.location.origin.includes("http://localhost") ? new Date(this.state.programme.date_debut.replace("Z","")) : new Date(this.state.programme.date_debut)
      //console.log("date_debut",this.state.programme.date_debut.replace("Z",""))
     // console.log("dates", date_debut, new Date())

     // console.log("program", this.state.programme)


      const evaluation_possible = new Date() >= date_debut

      const accessibilite_dict = {
        "lsf": "Langue des signes",
        "ss_titre": "Sous titrage",
        "audio_description": "Audio description"
      }

      const evaluation_dict = {
        "bon": "Bon",
        "mauvais": "À améliorer",
        "absent": "Absent"
      }

      const precisions_dict = {
        "partiel": "Sous titrage partiel",
        "erreur_retranscription": "Erreur de retranscription",
        "erreur_retranscriptio": "Erreur de retranscription",
        "pas_tps_lire": "Pas le temps de lire",
        "pas_bandeau": "Absence de bandeau noir",
        "manque_contraste": "Manque de contraste entre le texte et l'arrière plan",
        "non_sens": "Non-sens global",
        "fautes_francais": "Fautes de Français",
        "retard": "Sous-titrage décalé",
        "qui_parle": "On ne sait pas qui parle",
        "pas_code_couleur": "Pas de code couleurs",
        "respect_code_couleur": "Non-respect des couleurs",
        "taille_medaillon": "Taille du médaillon de l'interprète trop petite",
        "mauvais_cadrage": "Mauvais cadrage de l'interprète",
        "mauvaises_lumieres": "Mauvaises lumières sur l'interprète",
        "mauvais_contraste": "Mauvais contraste entre l’arrière-plan et l’interprète",
        "message_incomprehensible": "Message incompréhensible",
        "debit_non_adapte": "Débit non adapté",
        "message_approximatif": "Message approximatif",
        "mauvais_placement": "Mauvais placement de l'interprète",
        "superposition": "Superposition avec d'autres écrits",
        "mauvais_distinction": "Mauvaise distinction des interlocuteurs en cas d’échanges complexes",
        "coupure_interpretation": "Coupure de l’interprétation avant la fin de l’émission",
        "mauvaise_tenue": "Mauvaise tenue vestimentaire de l’interprète",
        "non_sens_partiel": "Non-sens partiel",
        "non_respect_deux_ligne_sttr": "Non-respect des 2 lignes de sous-titrage en différé et des 3 lignes en direct",
      }
      return (
        <DocumentTitle title={titre + " - Unanimes"}>
          <Switch>
            <Route path="/programme/:programmeID/evaluation/:accessibilite/profil" render={(props) => { return (this.state.evaluation !== "" /*&& !this.checkVote(this.state.accessibilite)*/ && this.state.vote_state != "sent") ? <Profil{...props} profilComplete={true} profil={this.state.profil} onClose={(profil) => { this.props.onProfilChange(profil); this.setState({ profil: profil }, this.sendVote) }} /> : <Redirect to={"/programme/" + this.state.emissionID + "/"} /> }} />
            <Route path="/programme/:programmeID/evaluation/:accessibilite/fin">
              {this.state.vote_state === "sent" && <EvaluationFin />}
              {this.state.vote_state === "error" && <ErreurVote onClick={() => this.sendVote()} />}
            </Route>
            <Route path="/programme/:programmeID/evaluation/:accessibilite/details/fin">
              {this.state.vote_state === "sent" && <EvaluationFin />}
              {this.state.vote_state === "error" && <ErreurVote onClick={() => this.sendVote()} />}
            </Route>
            <Route path="/">
              <div className="programme-page">
                <div className="programme-details">
                  <div className="row">
                    {photo_url ?
                      <div className="program-img">
                        <img src={photo_url.isEmpty || !photo_url ? "http://photos.plurimedia.fr/photos/cdc42420-1dc2-4961-a46d-db4f14dacdc2.jpg" : photo_url} alt="" />
                      </div> :
                      <div className='program-img  program-thumbnail-no-thumbnail'>


                        <div aria-hidden="true">
                          <img src={aucun_visuel} alt=""></img>
                          <p>Aucun visuel disponible</p>
                        </div>


                      </div>
                    }

                    <div className="program-desc">
                      <img className="chaine" alt={this.state.programme.chaine.titre} src={this.state.programme.chaine.photo_url} />
                      <h1 className="bold" tabIndex="-1" ref={this.myRef}>{titre + " -  " + date} </h1>
                      <p>{genre}</p>
                    </div>
                    <Route exact path="/programme/:programmeID">
                      {/*nb_votes > 0 && <div className="rating">
                        <img alt="" src={rating < 5 ? mauvais : (rating < 8 ? non_accessible : bon)}></img>
                        <div className="rating-text">
                          <p>Accessibilité {rating < 5 ? "à améliorer" : (rating < 8 ? "moyenne" : "bonne")}</p>
                          <p>Moyenne générale : {rating}/10</p>
                          <p>{nb_votes} vote{nb_votes > 1 && "s"}</p>
                        </div>
                  </div>*/}
                      {/*!has_lsf && !has_ss && <div className="rating">
                        <div className="img-rating moyen"><img alt="" src={moyen}></img></div>

                        <div className="rating-text">
                          <p>Programme non accessible</p>
                        </div>
                </div>*/}

                      {nb_votes > 0 &&
                        <div id="rating_table">
                          <table >

                            <thead>
                              <tr><th scope="col">Accessibilité</th>
                                <th scope="col">Notation globale (sur 10)</th>
                                <th scope="col">Ma note (sur 10)</th>

                              </tr>

                            </thead>
                            <tbody>
                              {nb_votes_stt > 0 &&
                                <tr>
                                  <th scope="row">Sous-titrage</th>
                                  <td>{rating_stt} <img alt="" src={this.getPicto(rating_stt)} /> ({nb_votes_stt} vote{nb_votes_stt > 1 && "s"})</td>
                                  {userRatings.hasOwnProperty("ss_titre") ?
                                  <td>{userRatings.ss_titre} <img alt="" src={this.getPicto(userRatings.ss_titre)} /></td> :
                                  <td>Non noté</td>
                                  }
                               
                                </tr>}
                              {nb_votes_lsf > 0 &&
                                <tr>
                                  <th scope="row">Langue des signes (technique)</th>
                                  <td>{rating_lsf_tech} <img alt="" src={this.getPicto(rating_lsf_tech)} /> ({nb_votes_lsf} vote{nb_votes_lsf > 1 && "s"})</td>
                                  {userRatings.hasOwnProperty("lsf") ?
                                  <td>{userRatings.lsf} <img alt="" src={this.getPicto(userRatings.lsf)} /></td> :
                                  <td>Non noté</td>
                                  }
                                </tr>}
                              {nb_votes_lsf > 0 && rating_lsf_qual != null &&
                                <tr>
                                  <th scope="row">Langue des signes (qualité)</th>
                                  <td>{rating_lsf_qual} <img alt="" src={this.getPicto(rating_lsf_qual)} /> ({nb_votes_lsf} vote{nb_votes_lsf > 1 && "s"})</td>
                                  {userRatings.hasOwnProperty("lsf_qual") ?
                                  <td>{userRatings.lsf_qual} <img alt="" src={this.getPicto(userRatings.lsf_qual)} /></td> :
                                  <td>Non noté</td>
                                  }
                                </tr>}
                            </tbody>

                          </table>

                        </div>


                      }
                    </Route>
                    <Route path="/programme/:programmeID/evaluation/:accessibilite/">
                      <EvaluationTitle />
                    </Route>
                  </div>
                </div>

                <Switch>
                  <Route exact path="/programme/:programmeID">
                    {/*this.state.votes_historique.length === 1 && <h2>Mon évaluation pour ce programme</h2>*/}
                    {/*this.state.votes_historique.length > 1 && <h2>Mes évaluations pour ce programme</h2>*/}
                    {this.state.votes_historique.length > 0 &&
                      this.state.votes_historique.map(vote =>
                        <div>
                          <h2>Vote du {vote.date}</h2>
                          <p>Accessibilité évalué : {accessibilite_dict[vote.accessibilite]}</p>
                          <p>Evaluation : {evaluation_dict[vote.evaluation]}</p>
                          {vote.evaluation === "mauvais" && vote.precision && vote.precision.length > 0 && <div>
                            <p>Détails évaluation</p>
                            <ul>
                              {vote.precision && vote.precision.map(element =>
                                <li key={element}>{precisions_dict[element]}</li>
                              )}
                            </ul>
                          </div>}
                        </div>
                      )

                    }
                    {evaluation_possible &&
                      <div style={{ clear: "both" }} className="evaluation_links">
                        <Route exact path="/programme/:programmeID">
                          <p className="float-right">Cliquer ci-dessous pour évaluer</p>
                        </Route>
                        <div style={{ clear: "both" }} ></div>
                        {/*has_ss ? a_vote_ss ?
                          <a className="evaluation_link row first disabled" aria-label="Vous avez déjà évalué le sous-titrage">
                            <img alt="" src={ss_picto} />
                            <div className="evaluation_link_text"><p className="bold">Sous-titrage</p>
                              <p>Déjà voté</p>

                            </div>
                          </a> : */<Link onClick={(event) => this.handleSSClick(event)} to={eval_st_path} className="evaluation_link row first" aria-label={has_ss ? "Evaluer le sous-titrage" : "Evaluer le sous-titrage - Signalé comme non disponible"}>
                            <img alt="" src={ss_picto} />
                            <div className="evaluation_link_text"><p className="bold">Sous-titrage</p>
                              {!has_ss && !a_vote_ss && <p>Signalé comme non disponible</p>}
                              {a_vote_ss && <p>Déjà voté</p>}
                            </div>
                            <span aria-hidden="true">{'>'}</span>
                          </Link>
                          /*)
    
    
                            :
                            <a className="evaluation_link row first disabled" aria-label="Evaluer le sous-titrage - Non disponible">
                              <img alt="" src={ss_picto} />
                              <div className="evaluation_link_text"><p className="bold">Sous-titrage</p>
                                <p>Non disponible</p>
                              </div>
                            </a>*/
                        }

                        {/*has_lsf true ? (a_vote_lsf ?
                          <a className="evaluation_link row disabled" aria-label="Vous avez déjà évalué la mangue des signes">
                            <img alt="" src={lsf_picto} />
                            <div className="evaluation_link_text"><p className="bold">LSF</p>
                              <p>Déjà voté</p>
                            </div>

                          </a>

                          : */<Link onClick={(event) => this.handleLSFClick(event)} to={eval_lsf_path} className="evaluation_link row" aria-label={has_lsf ? "Evaluer la langue des signes" : "Evaluer la langue des signes - Signalé comme non disponible"}>
                            <img alt="" src={lsf_picto} />
                            <div className="evaluation_link_text"><p className="bold">LSF</p>
                              {/*!has_lsf && <p>Signalé comme non disponible</p>*/}
                              {a_vote_lsf && <p>Déjà voté</p>}
                              
                              </div>
                            <span aria-hidden="true">{'>'}</span>
                          </Link>/*)
                        :
                        <a className="evaluation_link row disabled" aria-label="Evaluer la langue des signes - Non disponible">
                          <img alt="" src={lsf_picto} />
                          <div className="evaluation_link_text"><p className="bold">LSF</p>
                            <p>Non disponible</p>
                          </div>

                        </a>*/

                        }

                        {/*has_audio_description ? (a_vote_ad ?
                        <a className="evaluation_link row center disabled" aria-label="Evaluer l'audio description - Déjà voté">
                          <img alt="" src={ad_picto} />
                          <div className="evaluation_link_text"><p className="bold">Audio-description</p>
                            <p>Déjà voté</p>
                          </div>

                        </a> :
                        <Link onClick={() => this.setAccessibiliteEvaluee("audio_description")} to={eval_ad_path} className="evaluation_link row center" aria-label="Evaluer l'audio description">
                          <img alt="" src={ad_picto} />
                          <div className="evaluation_link_text"><p className="bold">Audio-description</p>

                          </div>
                          <span aria-hidden="true">></span>
                        </Link>)
                        : <a className="evaluation_link row center disabled" aria-label="Evaluer l'audio description - Non disponible">
                          <img alt="" src={ad_picto} />
                          <div className="evaluation_link_text"><p className="bold">Audio-description</p>
                            <p>Non disponible</p>
                          </div>

                        </a>
                      */}
                      </div>
                    }
                    {!evaluation_possible && <p style={{ clear: "both" }} >L'évaluation n'est possible que sur les programmes passés ou en cours.</p>}
                  </Route>

                 {/* <Route exact path="/programme/:programmeID/evaluation/:accessibilite" render={(props) => { return <Evaluation{...props} has_lsf={has_lsf} has_ss={has_ss} onClick={(evaluation) => this.setEvaluation(evaluation)}/>}} />*/}

                  <Route exact path="/programme/:programmeID/evaluation/:accessibilite" render={(props) => { return (/*!this.checkVote(this.state.accessibilite)*/ this.state.vote_state != "sent") ? <Evaluation{...props} has_lsf={has_lsf} has_ss={has_ss} onClick={(evaluation) => this.setEvaluation(evaluation)} /> : <Redirect to={"/programme/" + this.state.emissionID + "/"} /> }} />

                  <Route exact path="/programme/:programmeID/evaluation/:accessibilite/details" render={(props) => { return (this.state.evaluation !== "" /*&& !this.checkVote(this.state.accessibilite)*/ &&  this.state.vote_state != "sent") ? <EvaluationDetails{...props} precision={this.state.precision} changeCheckBox={(value) => this.changeCheckBox(value)} rating_qual_lsf={this.state.rating_qual_lsf} onLSFRatingChange={rating => this.setState({ rating_qual_lsf: rating }) }/> : <Redirect to={"/programme/" + this.state.emissionID + "/"} /> }} />




                </Switch>

              </div>
            </Route>
          </Switch>
        </DocumentTitle>



      )
    } else {
      return (<div></div>)
    }
  }
}




export default ProgrammeDetails;