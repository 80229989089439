import React, { Component } from 'react';
import Programme from './Programme';
import ProgrammeDetails from './ProgrammeDetails'
import aucun_programme from '../../img/aucun-programme.png'
import {
	Switch,
	Route,
} from "react-router-dom";
import DocumentTitle from 'react-document-title';



class Programmes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date: this.props.date || new Date(),
			chaine: "",
			liste_programmes: "",
			onglet: this.props.onglet
			//oneProgramExpanded: null
		};
		this.getProgrammes = this.getProgrammes.bind(this);
	}

	componentDidMount() {
		this.getProgrammes(this.props.onglet, this.props.date, this.props.chaine);
	}
	componentWillReceiveProps(props) { // Quand change d'onglet dans le parent -> force à re-render
		console.log("willReceiveProps");
		this.getProgrammes(props.onglet, props.date, props.chaine);
	}

	/*static getDerivedStateFromProps(props, state) {
		// À chaque fois que l’utilisateur actuel change, on réinitialise
		// tous les aspects de l’état qui sont liés à cet utilisateur.
		// Dans cet exemple simple, il ne s’agit que de l’e-mail.
		console.log("getDerivatedState props",props)
		console.log("getDerivatedState state",state)
		if (props.onglet !== state.onglet || props.date.toISOString() !== state.date.toISOString()) {
			
		  return {
			date: props.date,
			onglet: props.onglet
		  };
		}
		return null;
	}*/

	/*componentDidUpdate(){
		console.log("state programmes", this.state)
	}*/

	getProgrammes(onglet, date, chaine) {
		/*
		console.log('Call API à PluriMedia', this.state);
		var jsonDataFromXml = new XMLParser().parseFromString(DataProgrammeTest);
		var ID_programs = jsonDataFromXml.children.map((programme, index) => {
			let id = programme.getElementsByTagName("EMISSION")[0].attributes.CLEEMI;
			return (id);
		});
		
		this.setState({
			liste_programmes: jsonDataFromXml.children.map((programme, index) => {
				return (<Programme key={index} programme={parseProgramme(programme)} onClick={()=>this.props.handleSelection(programme)}/>);
			})
		});
		*/
		console.log('Call API Back récup Programmes ' + onglet);

		let url;

		if (onglet === "live") { // Si LIVE
			url = this.props.base_url + '/api/program/'; //http://localhost:3001
		} else if (onglet === "replay" && (chaine === "" || chaine === "all")) { // Si REPLAY
			// Programmes de la journée avec replay

			url = this.props.base_url + '/api/program?attribut=replay&date=' + date.toISOString().split('T').slice(0, 1)[0]; //window.location.origin + 
			console.log("url", url);
		} else if (onglet === "replay") { // Si REPLAY
			// Programmes de la journée avec replay

			url = this.props.base_url + '/api/program?attribut=replay&date=' + date.toISOString().split('T').slice(0, 1)[0] + '&chaineID=' + chaine; //window.location.origin + 
			console.log("url", url);
		} else { // SINON
			url = this.props.base_url + '/api/program/'; //window.location.origin + 
		}
		const chaines_ordre = this.props.chaines_ordre; 
		fetch(url, { method: "GET" })
			.then(response => response.json())
			.then((results) => {
				// Changer ordre en fonction des chaines:
				results = results.sort(function (a, b) {
					if (a.chaine.id in chaines_ordre && b.chaine.id in chaines_ordre) {
						return chaines_ordre[a.chaine.id] - chaines_ordre[b.chaine.id]
					} else if (a.chaine.id in chaines_ordre) {
						return -1;
					} else {
						return 1;
					}
				}
				);
				this.setState({
					liste_programmes: results.map((programme, index) => {
						return (<Programme from={"accueil"} key={index} programme={programme} profil={this.props.profil} onClick={() => this.props.handleSelection(programme)} />);
					})
				});
			})
			.catch((error) => {
				console.log(error);
				// Pour assurer un affichage meme si aucun retour de l'API
				this.setState({
					liste_programmes: []
				});
			});
	}
	render() {

		return (
			<DocumentTitle title="CommonTV">
				<Switch>
					<Route path="/programme/:programmeID" render={(props) => <ProgrammeDetails{...props} base_url={this.props.base_url} isAuthed={true} />} />
					<Route path="/">
						{this.state.liste_programmes.length > 0 && <div className="programmes row" style={{ marginTop: 10 }}>
							{this.state.liste_programmes}
						</div>}
						{this.state.liste_programmes && this.state.liste_programmes.length === 0 &&

							<div class="aucun-programme" style={{ clear: "both" }}>
								<img src={aucun_programme} alt=""></img>
								<p>Aucun programme disponible</p>
							</div>

						}
						{/*	<Box style={{ marginTop: 15, border: "1px solid grey", borderRadius: "7px" }} p={3}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Grid container justify="space-around">
						<KeyboardDatePicker // https://material-ui-pickers.dev/api/KeyboardDatePicker
							margin="normal" id="date-picker-dialog" label="Date de diffusion" format="dd/MM/yyyy" initialFocusedDate={null} value={this.state.date} onChange={(date) => { this.setState({ date: date }); }} KeyboardButtonProps={{
								'aria-label': 'change date',
							}} />
					</Grid>
				</MuiPickersUtilsProvider>

				<FormControl style={{ width: "50%" }}><br />
					<InputLabel id="chaine">Choix de chaîne</InputLabel>
					<Select labelId="chaine" id="chaine_select" value={this.state.chaine} onChange={(event) => this.setState({ chaine: event.target.value })}>
						<MenuItem value={"TF1"}>TF1</MenuItem>
						<MenuItem value={"FR2"}>FR2</MenuItem>
						<MenuItem value={"FR3"}>FR3</MenuItem>
						<MenuItem value={"CANAL+"}>CANAL+</MenuItem>
						<MenuItem value={"M6"}>M6</MenuItem>
					</Select>
				</FormControl>
				<br />
				<Button variant="contained" color="primary" onClick={this.getProgrammes} style={{ marginTop: 10 }}>
					Rechercher
				  </Button>
						</Box>*/}
					</Route>
				</Switch>
			</DocumentTitle>);
	}
}

export default Programmes;