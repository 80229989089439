import React, {Component, useState} from 'react';


import {
  BrowserRouter as Router,
  Route,
  Link,
} from "react-router-dom";

import aucun_visuel from '../../img/aucun-visuel.png'


export default (props) => {

  const [voteWindowOpen] = useState(false);
  const [voteButtonDisabled] = useState(false);
  const [thumbnailState, setThumbnailState] = useState(props.programme.photo_url  ? 'loading' : 'no-thumbnail')

  const titre = props.programme.titre ;
  const date = props.programme.heure ;
  const genre = props.programme.genre;
  const rating = Math.round(props.programme.rating*100)/100; //arrondi à 2 chiffres après la virgule
  const vote = props.vote
  const accessibilite_dict = {
    "lsf" : "Langue des signes",
    "ss_titre" : "Sous titrage",
    "audio_description" : "Audio description"
  }

  const evaluation_dict = {
    "bon": "Bon",
    "mauvais": "À améliorer",
    "absent": "Absent"
  }
 
  var jour = props.programme.jour // AAAAMMJJ
  if (jour.includes("-")){
    const dayArray = jour.split('-')
    jour = dayArray[2] + "/" + dayArray[1] + "/" + dayArray[0]
  }else{
    jour = jour.substring(6) + "/" + jour.substring(4,6) + "/" + jour.substring(0,4)
  }
  
  var ariaLabel =props.programme.chaine.titre +" - " + titre + " -  " + (props.from==="accueil"?"":(jour+ "  - ")) + date + " - " + genre;
  if(vote!=null && props.from !=null && props.from==="historique"){
    ariaLabel += " - Mode évalué : " + accessibilite_dict[vote.accessibilite] + " - Evaluation : " + vote.evaluation
  }
  if(props.from != null && props.from==="classement"){
    ariaLabel += " - Note moyenne : " + rating;
  }
  return <div className="program col-md-4">
    <Link aria-label={ariaLabel} to={"/programme/" + props.programme.id} onClick={props.onClick}>
      <div aria-hidden="true">
        <div className={['program-thumbnail', props.programme.photo_url ? '':'program-thumbnail-no-thumbnail'].join(' ')}>
          { props.programme.photo_url  && 
            <img 
              src={props.programme.photo_url} 
              alt="" 
              onLoad={() => setThumbnailState('loaded')}
              onError={() => setThumbnailState('error')}
          /> }
          {(!props.programme.photo_url || (thumbnailState==='no-thumbnail'||thumbnailState==="error")) && 

            <div>
              <img src={aucun_visuel} alt=""></img>
              <p>Aucun visuel disponible</p>
            </div>

          }
        </div>
        <div className="program-desc">
          <p className="bold">{props.programme.chaine.titre + " : " +titre + " -  " + (props.from==="accueil"?"":(jour+ "  - ")) + date} </p>
          <p>{genre}</p>
          <Route path="/classement"><p>Note moyenne : {rating}</p></Route>

        {props.vote != null && <Route path="/historique"><p>Evaluation : {accessibilite_dict[vote.accessibilite]} - {evaluation_dict[vote.evaluation]}</p></Route> }
          
        </div>
      </div>
      <span className="mobile-only program-link" aria-hidden="true">{'>'}</span>
    </Link>


 </div>
}
