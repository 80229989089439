import { useHistory } from 'react-router-dom'
import React, {Component} from 'react';
import {
  Switch,
  Route,
  Link,
} from "react-router-dom";

function BackButton(props) {
  let history = useHistory()
  console.log("history",history);
  return (

    <Switch>
      <Route exact path="/" />
      <Route exact path="/historique" />
      <Route exact path="/classement" />
      <Route exact path="/a_propos" />
      <Route exact path="/profil/"/>
      <Route exact path="/programme/:programmeID/evaluation/:accessibilite/fin"/>
      <Route path="/programme/:programmeID/evaluation/:accessibilite/details/fin"/>
      <Route path="/profil/mode_communication"/>
      <div className="list-group menu-page back-button">
            <Switch>
              <Route exact path="/programme/:programmeID/">
                {props.from==="accueil" && <Link to="/" className="list-group-item list-group-item-action"><div>{<span aria-hidden="true">{"<"}</span>}Retour liste programmes</div></Link>}
                {props.from==="historique" && <button className="list-group-item list-group-item-action" onClick={() => history.goBack()}><div>{<span aria-hidden="true">{"<"}</span>}Retour Historique</div></button>}
                {props.from==="classement" && <button className="list-group-item list-group-item-action" onClick={() => history.goBack()}><div>{<span aria-hidden="true">{"<"}</span>}Retour Classement</div></button>}
              </Route>
              <Route exact path="/programme/:programmeID/evaluation/:accessibilite/fin"/>
              <Route path="/">
              <button className="list-group-item list-group-item-action" onClick={() => history.goBack()}><div>{<span aria-hidden="true">{"<"}</span>}
                
                <Switch>
                  <Route exact path="/programme/:programmeID/evaluation/:accessibilite/">
                      Retour fiche programme
                  </Route>
                  <Route exact path="/programme/:programmeID/evaluation/:accessibilite/details">
                      Retour évaluation
                  </Route>
                  <Route path="/">
                    Retour
                  </Route>
                  
                </Switch>
                </div>
              </button>

              </Route>
            </Switch>
            
      </div>
    </Switch>
  )
}

export default BackButton;