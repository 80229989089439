
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Programme from './Programme/Programme';
import ProgrammesFilters from './ProgrammesFilters';
import $ from 'jquery';
import ls from 'local-storage';
import aucun_programme from '../img/aucun-programme.png'

export default (props) => {

    const [historiqueByDay, setHistoriqueByDay] = useState([])
    const [displayFilters, setDisplayFilters] = useState(false)
    

    useEffect(() => {
        const fetchProgrammes = async () => {
            // on va chercher 3 programmes pour tester
            /*const programmes = (await allProgrammes()).slice(0, 3);
            setHistoriqueByDay([
                {day: '24/03/2020', programmes},
                {day: '23/03/2020', programmes}
            ]);*/



            var historique = ls('historique');
            if (historique) {
                console.log("historique ls2", historique)
                let dict = JSON.parse(historique)


                // Create items array
                var items = Object.keys(dict).map(function (key) {
                    return [key, dict[key]];
                });

                // Sort the array based on the key
                items.sort(function (first, second) {
                    console.log("fist", first[0]);
                    console.log("second", second[0]);
                    return second[0] < first[0] ? -1 : 1;
                });

                //Repasser de YYYYMMJJ à JJMMYYYY
                console.log("items", items);
                for (var i = 0; i < items.length; i++) {
                    console.log("new date", i);
                    const dateArray = items[i][0].split("-")
                    const date = [dateArray[2], dateArray[1], dateArray[0]].join('/');
                    console.log("new date", date);
                    items[i][0] = date
                }


                setHistoriqueByDay(items);
            }
        };
        fetchProgrammes();
        $("#historique-h1").focus();
    }, []);



    return <div className="historique">
        <DocumentTitle title='Historique - CommonTV' />

        <div className="d-flex justify-content-between align-items-center mx-3 mb-3">
            <h1 id="historique-h1" tabIndex="-1" >Mon historique</h1>
            {/* <button aria-label="Filtres" aria-controls="filtres-programmes" aria-haspopup="true" aria-expanded={displayFilters} className={`filters-toggle ${displayFilters ? 'filters-toggle__open' : ''}`} onClick={() => setDisplayFilters(!displayFilters)}>
            </button>*/}
        </div>
        {/*displayFilters && <ProgrammesFilters onChange={(cats, startDate, endDate) => setDisplayFilters(false)} />*/}

        {
            historiqueByDay.map((dayHistorique, index) =>
                <div key={dayHistorique[0]}>
                    <h2 className="historique-day text-left">Historique du <b>{dayHistorique[0]}</b></h2>
                    <div className="row">
                        {dayHistorique[1].reverse().map(vote => <Programme from={"historique"} key={vote.programme.id} vote={vote} programme={vote.programme} profil={props.profil} onClick={() => props.handleSelection(vote.programme)} />)}
                    </div>
                </div>)}

        {
            historiqueByDay.length === 0 &&
            <div class="aucun-programme" style={{ clear: "both" }}>
                <img src={aucun_programme} alt=""></img>
                <p>Votre historique est vide pour le moment.</p>
            </div>
        }
    </div>

}