import React, {Component} from 'react';
import DocumentTitle from 'react-document-title';



class CharteDetail extends Component{
  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }
  componentDidMount(){
	  this.myRef.current.focus();
  }

  render (){
    return (
      <DocumentTitle title='Charte - CommonTV'>
        <div className="charte-details text-left">
          <h1 tabIndex="-1" ref={this.myRef}>Common TV® <br></br>Charte d'usage</h1>
          <p>
          Afin de participer à l’amélioration de l’accessibilité des programmes audiovisuels, Unanimes l’Union des Associations Nationales pour l’Inclusion des personnes Malentendantes ou Sourdes propose un outil d’évaluation : Common TV®
          </p>

          <p>
          La présente Charte définit les conditions générales d'utilisation de cet outil mis à disposition des utilisateurs et précise les engagements pris par les utilisateurs en acceptant cette charte.
          </p>

          <p>
          Common TV® est une marque déposée par Unanimes qui est seul titulaire des droits afférents.
          </p>

          <h2>1 - <u>Description du service proposé</u></h2>
          <p>
          COMMON TV® permet aux téléspectateurs d’évaluer, à partir d’items prédéfinis, le niveau du sous-titrage ou de l’interprétation en Langue des Signes Française (LSF) du programme visionné. Seuls les programmes dont l’accessibilité est prévue et annoncée dans la grille de programme pourront être évalués.<br></br>
          L’évaluation de l’audiodescription sera possible dans un second temps.
          </p>

          <p>Les items d’évaluation du sous-titrage et de la LSF ont été établis à partir des critères spécifiés dans la <a href="https://www.csa.fr/Reguler/Espace-juridique/Les-relations-du-CSA-avec-les-editeurs/Chartes/Charte-relative-a-la-qualite-du-sous-titrage-a-destination-des-personnes-sourdes-ou-malentendantes-Decembre-2011" target="_blank">charte « relative à la qualité́ du sous-titrage à destination des personnes sourdes ou malentendantes »<span className="screen-reader-text"> (Ouvre un nouvel onglet)</span></a> et <a href="https://www.csa.fr/Reguler/Espace-juridique/Les-relations-du-CSA-avec-les-editeurs/Chartes/Charte-de-qualite-pour-l-usage-de-la-Langue-des-Signes-Francaise-dans-les-programmes-televises-15-janvier-2015" target="_blank">la charte « de qualité pour l’usage de la LSF dans les programmes télévisés ».<span className="screen-reader-text"> (Ouvre un nouvel onglet)</span></a></p>

          <p>L’évaluation porte sur un programme en cours de diffusion ou disponible via un service de vidéo à la demande proposé par les chaînes de télévision (Replay).</p>

          <p>Les évaluations ont pour objectif de générer des données statistiques relatives à la qualité de l’accessibilité d’un programme.</p>

          <p>Ces données sont à destination des chaines de télévision partenaires de Common TV®.</p>

          <h2>2 - <u>Préalables à l’évaluation d’un programme</u></h2>

          <p>Le mode de réception (TNT, Box, Satellite…), le matériel de visionnage (télévision, mobile, tablette…), la localisation de l’utilisateur sont pris en compte dans la remontée des données car ces éléments peuvent impacter la qualité de l’accessibilité du programme. L’utilisateur de Common TV® s’engage à renseigner ces éléments.</p>

          <p>A noter : Lorsqu’un programme est en direct, le sous-titrage est réalisé lui aussi en direct. Il peut alors y avoir un décalage plus important entre ce qui est dit et le sous- titrage. Ce décalage est à prendre en compte par l’utilisateur lors de son évaluation.</p>

          <h2>3 - <u>Engagements de l’utilisateur</u></h2>

          <p>L’utilisateur s’engage à apporter sa contribution afin d’évaluer la qualité de l’accessibilité audiovisuelle. Il s’engage à évaluer objectivement le programme regardé dans un soucis d’amélioration du service.</p>

          <h2>4 - <u>Données relatives à l’utilisateur</u></h2>

          <p>L’évaluation est totalement anonyme : la participation à l’évaluation ne nécessite pas de création de compte utilisateur et aucune donnée personnelle n’est collectée. Néanmoins, afin de garantir l’évaluation unique d’un programme par utilisateur, un jeton de sécurité est affecté à chaque utilisateur.</p>

          <h2>5 - <u>Remontée des données</u></h2>

          <p>Les données relatives à l’évaluation du programme seront collectées et remontées à la chaine concernée/diffusant le programme évalué et partenaire de Common TV®.</p>

          <p>Chaque chaîne de télévision partenaire disposera des données collectées selon une fréquence définie dans le cadre du partenariat mis en place avec Unanimes</p>

          <h2>6 - <u>Acceptation de la présente charte</u></h2>

          <p>L’utilisation de Common TV® vaut acceptation de la présente charte.</p>
        </div>
      </DocumentTitle>
    )
  }
}

export default CharteDetail;