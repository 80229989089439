import React, { Component } from 'react';

import Button from '../Button';
import super_picto from '../../img/emoticones/img-picto-super.png'
import ss_picto from '../../img/pictos-fond-choix/img-fond-soustitres.png'
import lsf_picto from '../../img/pictos-fond-choix/img-fond-lsf.png'
//import ad_picto from '../../img/pictos-fond-choix/img-fond-audiodescription.png'
import animateScrollTo from 'animated-scroll-to';
import $ from 'jquery';


class ProfilModeCommunication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || "",

        }
        this.myRef = React.createRef();
        this.setValue = this.setValue.bind(this);
        this.hasScrolled = false;
    }


    componentDidMount() {
        console.log("profil complete", this.props.profilComplete);
        this.myRef.current.focus();
        $(window).scrollTop(0);

    }

    componentDidUpdate() {
        if (document.getElementsByClassName('rbtn').length > 0 && !this.hasScrolled) {
            animateScrollTo(document.querySelector('.rbtn'));
            this.hasScrolled = true
        }
    }

    setValue(event, value) {
        event.preventDefault();
        this.setState({ value: value })

    }
    render() {
        return (
            <div>
                <div className="content localisation">
                    <img src={super_picto} alt="" className="title_picto" />

                    <h1 tabIndex="-1" ref={this.myRef}>{this.props.profilComplete ? "Mon mode de communication" : "Super !"}</h1>

                    <p id="rg1_label">Je choisis mon mode de communication privilégié :</p>
                    <div className="cards" role="radiogroup" aria-labelledby="rg1_label">
                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "Sous-titrage")} aria-checked={this.state.value == "Sous-titrage"}>
                                <div className={this.state.value == "Sous-titrage" ? "card active" : "card"}>
                                    <img src={ss_picto} alt="" />
                                    <p>Sous-titrage</p>
                                </div>
                            </a>
                        </div>

                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "LSF")} aria-checked={this.state.value == "LSF"}>
                                <div className={this.state.value == "LSF" ? "card active" : "card"}>
                                    <img src={lsf_picto} alt="" />
                                    <p>LSF</p>
                                </div>
                            </a>
                        </div>

                        {/*<div className="card-column center">
                            <a role="radio" href="#" onClick={(event) => this.setValue(event, "Audio-description")} aria-checked={this.state.value == "Audio-description"}>
                                <div className={this.state.value == "Audio-description" ? "card active" : "card"} >
                                    <img src={ad_picto} alt="" />
                                    <p>Audio-description</p>
                                </div>
                            </a>
                        </div>*/}

                    </div>

                    {(this.state.value != "") && !this.props.match &&
                        <Button path="/profil/mode_reception" className="mobile-only rbtn" onClick={() => this.props.onClose(this.state.value)} text="Étape suivante" />
                    }

                    {(this.state.value != "") && this.props.match &&
                        <Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/profil/mode_reception"} className="mobile-only rbtn" onClick={() => this.props.onClose(this.state.value)} text="Étape suivante" />
                    }
                    {this.state.value == "" && <p className="screen-reader-text">Vous devez selectionnez un mode de communication pour pouvoir continuer</p>}


                </div>
                <div className="bottom-bar">
                    {(this.state.value != "") && !this.props.match &&
                        <Button path="/profil/mode_reception" className="desktop-only rbtn" onClick={() => this.props.onClose(this.state.value)} text="Étape suivante" />
                    }

                    {(this.state.value != "") && this.props.match &&
                        <Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/profil/mode_reception"} className="desktop-only rbtn" onClick={() => this.props.onClose(this.state.value)} text="Étape suivante" />
                    }
                </div>
            </div>

        )
    }
}

export default ProfilModeCommunication;