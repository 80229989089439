import React, { Component } from 'react';

import Button from '../Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import $ from 'jquery';
import pin from '../../img/menu/img-maville.png'
// import materiel_picto from '../../img/emoticones/img-picto-materiel.png'
import tv_picto from '../../img/pictos-fond-choix/img-fond-tv.png'
import mobile_picto from '../../img/pictos-fond-choix/img-fond-mobile.png'
import pc_picto from '../../img/pictos-fond-choix/img-fond-ordinateur.png'
import animateScrollTo from 'animated-scroll-to';

class ProfilMaterielLocalisation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materiel: props.materiel || "",
            localisation: props.localisation || "Auvergne-Rhône-Alpes",

        }
        this.myRef = React.createRef();
        this.setMateriel = this.setMateriel.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.hasScrolled = false;
    }


    componentDidMount() {
        this.myRef.current.focus();
        $(window).scrollTop(0);
        $("svg.MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined").remove();
        // var ul_france_metropolitaine = $('<ul></ul>')
        // $('.MuiList-root.MuiMenu-list.MuiList-padding').prepend(ul_france_metropolitaine)
    }

    componentDidUpdate() {
        console.log("component did update")
        if (document.getElementsByClassName('rbtn').length > 0 && !this.hasScrolled) {
            animateScrollTo(document.querySelector('.rbtn'));
            this.hasScrolled = true;
        }
    }

    setMateriel(event, materiel) {
        event.preventDefault();
        this.setState({ materiel: materiel });
        this.props.handleChange(materiel, this.state.localisation)

    }

    handleChange(event) {
        console.log(event.target.value)
        this.setState({ localisation: event.target.value })
        this.props.handleChange(this.state.materiel, event.target.value)
    }
    render() {
        return (
            <div>
                <div className="content materiel">
                    {/* <img src={materiel_picto} alt="" className="title_picto"/> */}
                    <h1 tabIndex="-1" ref={this.myRef}>Matériel et Localisation</h1>

                    <p id="rg1_label">Quel est mon matériel privilégié ?</p>
                    <div className="cards" role="radiogroup" aria-labelledby="rg1_label">
                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setMateriel(event, "TV")}
                                aria-checked={this.state.materiel == "TV"}>
                                <div className={this.state.materiel == "TV" ? "card first active" : "card first"}>
                                    <img src={tv_picto} alt="" />
                                    <p>Télévision</p>
                                </div>
                            </a>
                        </div>

                        <div className="card-column">
                            <a role="radio" href="#" onClick={(event) => this.setMateriel(event, "Mobile ou Tablette")}
                                aria-checked={this.state.materiel == "Mobile ou Tablette"}>
                                <div className={this.state.materiel == "Mobile ou Tablette" ? "card active" : "card"}>
                                    <img src={mobile_picto} alt="" />
                                    <p className="long-text">Mobile ou Tablette</p>
                                </div>
                            </a>
                        </div>

                        <div className="card-column center">
                            <a role="radio" href="#" onClick={(event) => this.setMateriel(event, "Ordinateur")}
                                aria-checked={this.state.materiel == "Ordinateur"}>
                                <div className={this.state.materiel == "Ordinateur" ? "card active" : "card"} >
                                    <img src={pc_picto} alt="" />
                                    <p>Ordinateur</p>
                                </div>
                            </a>
                        </div>



                    </div>

                    <p>Où suis-je lors de mon visionnage ?</p>

                    <FormControl variant="outlined" >
                        <InputLabel id="region-label">Région</InputLabel>
                        <Select
                            labelId="region-label"
                            id="region-select-outlined"
                            value={this.state.localisation}
                            onChange={this.handleChange}
                            label="Région"
                            native
                        >
                            {/*} <ListSubheader>France métropolitaine</ListSubheader>

                            <MenuItem value={"Auvergne-Rhône-Alpes"}>Auvergne-Rhône-Alpes</MenuItem>
                            <MenuItem value={"Bourgogne-Franche-Comté"}>Bourgogne-Franche-Comté</MenuItem>
                            <MenuItem value={"Bretagne"}>Bretagne</MenuItem>
                            <MenuItem value={"Centre-Val de Loire"}>Centre-Val de Loire</MenuItem>
                            <MenuItem value={"Corse"}>Corse</MenuItem>
                            <MenuItem value={"Grand Est"}>Grand Est</MenuItem>
                            <MenuItem value={"Hauts-de-France"}>Hauts-de-France</MenuItem>
                            <MenuItem value={"Île-de-France"}>Île-de-France</MenuItem>
                            <MenuItem value={"Normandie"}>Normandie</MenuItem>
                            <MenuItem value={"Nouvelle-Aquitaine"}>Nouvelle-Aquitaine</MenuItem>
                            <MenuItem value={"Occitanie"}>Occitanie</MenuItem>
                            <MenuItem value={"Pays de la Loire"}>Pays de la Loire</MenuItem>
        <MenuItem value={"Provence-Alpes-Côte d'Azur"}>Provence-Alpes-Côte d'Azur</MenuItem>*/}
                            <optgroup label="France métropolitaine">
                                <option value={"Auvergne-Rhône-Alpes"}>Auvergne-Rhône-Alpes</option>
                                <option value={"Bourgogne-Franche-Comté"}>Bourgogne-Franche-Comté</option>
                                <option value={"Bretagne"}>Bretagne</option>
                                <option value={"Centre-Val de Loire"}>Centre-Val de Loire</option>
                                <option value={"Corse"}>Corse</option>
                                <option value={"Grand Est"}>Grand Est</option>
                                <option value={"Hauts-de-France"}>Hauts-de-France</option>
                                <option value={"Île-de-France"}>Île-de-France</option>
                                <option value={"Normandie"}>Normandie</option>
                                <option value={"Nouvelle-Aquitaine"}>Nouvelle-Aquitaine</option>
                                <option value={"Occitanie"}>Occitanie</option>
                                <option value={"Pays de la Loire"}>Pays de la Loire</option>
                                <option value={"Provence-Alpes-Côte d'Azur"}>Provence-Alpes-Côte d'Azur</option>
                            </optgroup>
                            <optgroup label="Outre-mer">
                                <option value={"Guadeloupe"}>Guadeloupe</option>
                                <option value={"Guyane"}>Guyane</option>
                                <option value={"La Réunion"}>La Réunion</option>
                                <option value={"Martinique"}>Martinique</option>
                                <option value={"Mayotte"}>Mayotte</option>
                            </optgroup>


                        </Select>
                        <img className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" alt="" src={pin}></img>
                    </FormControl>

                    {(this.state.materiel != "") && (this.state.localisation != "") && !this.props.match &&
                        <Button path="/" className="mobile-only rbtn" onClick={this.props.onClose} text="Valider" />
                    }

                    {(this.state.materiel != "") && (this.state.localisation != "") && this.props.match &&
                        <Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/fin"} className="mobile-only rbtn" onClick={this.props.onClose} text="Valider" />
                    }
                    {((this.state.materiel == "") || (this.state.localisation == "")) &&
                        <p className="screen-reader-text">Vous devez selectionnez un appareil et une région pour pouvoir continuer</p>
                    }

                </div>
                <div className="bottom-bar">
                    {(this.state.materiel != "") && (this.state.localisation != "") && !this.props.match &&
                        <Button path="/" className="desktop-only rbtn" onClick={this.props.onClose} text="Valider" />
                    }

                    {(this.state.materiel != "") && (this.state.localisation != "") && this.props.match &&
                        <Button path={"/programme/" + this.props.match.params.programmeID + "/evaluation/" + this.props.match.params.accessibilite + "/fin"} className="desktop-only rbtn" onClick={this.props.onClose} text="Valider" />
                    }
                </div>
            </div>

        )
    }
}

export default ProfilMaterielLocalisation;