import React, {Component} from 'react';


const iOSSafari = /iP(ad|od|hone)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent));
const isAndroid = /android/i.test(window.navigator.userAgent);

class AddToHomeScreenInfo extends Component{
  constructor(props){
    super(props);
    this.myRef = React.createRef();
  }
  componentDidMount(){
	  this.myRef.current.focus();
  }

  render (){
    return (
        <div className="addToHomeScreenPage">
          <h1 tabIndex="-1" ref={this.myRef}>Le saviez vous ?</h1>
          <p>Vous pouvez installez l'application directement sur l'écran d'accueil de votre appareil pour un meilleur confort d'utilisation</p>
          <p>Pour celà : </p>
          <ul>
              { iOSSafari && <li>Allez dans le menu partage de votre navigateur</li> }
              { isAndroid && <li>Allez dans le menu de votre navigateur</li> }
              <li>Selectionnez "Ajouter à l'écran d'accueil"</li>
          </ul>
          <button onClick={this.props.onClose} className="rbtn"><div>J'ai compris<span aria-hidden="true" data-icon=">"></span></div></button>
        </div>
     
    )
  }
}

export default AddToHomeScreenInfo;